<script>
    import { fly } from 'svelte/transition';
    import { Button } from 'svelte-mui'
    import Comentario from './Comentario.svelte'
    import Formulario from './Formulario.svelte'
    import globalStore from '../../globalStore'
    import DialogoReporte from '../Dialogos/DialogoReporte.svelte';
    import Signal from '../../signal'
    import CarpetaMedia from './CarpetaMedia.svelte';
    import { onMount, tick } from 'svelte';
    import PilaRespuestas from './PilaRespuestas.svelte';
    import config, {configStore} from '../../config'
    import ajustesConfigStore from '../Dialogos/ajustesConfigStore'
    import Spinner from '../Spinner.svelte';
    import { localStore} from '../../localStore'
    import { paginate, LightPaginationNav } from 'svelte-paginate'
    import { ComentarioEstado, CreacionRango } from '../../enums';
    import more from '../../icons/more-vertical.svg'

    export let hilo
    export let comentarios
    let modoTelefono = $globalStore.esCelular
    let nuevosComentarios = []
    let comentariosStore = localStore("Comentarios", {modoVivo : false});

    let stickies;

    function listarStickies() {
        stickies = comentarios.filter((c) => c.sticky);
    }   

    listarStickies();

    function cargarNuevosComentarios() {        
        comentarios = [...nuevosComentarios, ...comentarios]       
        nuevosComentarios = []
        comentarios.forEach(agregarComentarioADiccionario)
        comentarios.forEach(cargarRespuestas)
        stickies = stickies;
       

        // Añadir el restag a los comentarios tageados por este comentario
        comentarios = comentarios;
        items = comentarios
        currentPage = 1       
    }

    let diccionarioRespuestas = {}
    let diccionarioComentarios = {}
    
    function agregarComentarioADiccionario(comentario) {        
        diccionarioComentarios[comentario.id] = comentario
			let tags = comentario.contenido.match(/#([A-Z0-9]{8})/g)
			if(!tags) return;
			let id = comentario.id
			for(const tag of tags) {
				let otraId = tag.slice(1, 9)
				if(!diccionarioRespuestas[otraId]) diccionarioRespuestas[otraId] = []
                diccionarioRespuestas[otraId].push(id)
                diccionarioRespuestas[otraId] = diccionarioRespuestas[otraId]
            }
        diccionarioRespuestas = diccionarioRespuestas
    }

    function cargarRespuestas(comentario) {
        if(diccionarioRespuestas[comentario.id]) comentario.respuestas = [...diccionarioRespuestas[comentario.id]]
        else comentario.respuestas = []
        comentario.respuestas = Array.from(new Set(comentario.respuestas))
    }

	comentarios.forEach(agregarComentarioADiccionario)
	comentarios.forEach(cargarRespuestas)

    function onComentarioCreado(comentario) {
        if(hilo.reset200 == true){         
            if(comentarios.length == 51){              
                comentarios =[];              
            }
        }     
        
        hilo.vistas = comentario.vistas      
        nuevosComentarios = [comentario, ...nuevosComentarios]
        comentario.respuestas = []
        if($comentariosStore.modoVivo) cargarNuevosComentarios()       
    }

    function onComentarioBloqueado(comentarioId) {
          
        comentarios = [...nuevosComentarios, ...comentarios]       
        nuevosComentarios = []
        comentarios.forEach(agregarComentarioADiccionario)
        comentarios.forEach(cargarRespuestas)
        // Añadir el restag a los comentarios tageados por este comentario
        comentarios = comentarios;
        
       let index = 0
        comentarios.forEach(c => {
            if(c.id == comentarioId) {                
                comentarios[index].mediaId = "-1"
                comentarios[index].media = null;
                comentarios[index].contenido="[Comentario bajo revisión.]"  
                comentarios[index].comentarioDenunciado = true;             
                return;
            } 
            index++    
        }) 

        items = comentarios
    }

    function onComentarioBorrado(comentarioId) {
       let index = 0
        comentarios.forEach(c => {
            if(c.id == comentarioId) {                
                comentarios[index].estado = ComentarioEstado.eliminado               
                return;
            } 
            index++    
        }) 

        items = comentarios
    }

     Signal.coneccion.on("NuevoComentario", onComentarioCreado)
     Signal.subscribirseAHilo(hilo.id)
     Signal.coneccion.on("ComentariosEliminados", ids => {  
        comentarios = comentarios.filter(c => ! ids.includes(c.id))
        nuevosComentarios = nuevosComentarios.filter(c => ! ids.includes(c.id))

        stickies = stickies.filter((s) => !ids.includes(s.id));
        stickies = stickies.map((s) => {
                if (ids.includes(s.id)) s.estado = ComentarioEstado.eliminado;
                return s;
            });
     })
     
     Signal.coneccion.on("NuevoSticky", (id, sticky) => {  
        
         if(id != undefined && id != null  && id.id != ""){
            
            diccionarioComentarios[id].sticky = sticky;

            comentarios = comentarios;

            if (sticky) {
                if (!stickies.some((s) => s.id == id)) {
                    stickies.unshift(diccionarioComentarios[id]);
                    stickies = stickies.sort((c1, c2) => c2.creacion - c1.creacion);
                }

                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0

            } else {
                stickies = stickies.filter((s) => s.id != id);   
            }
        }
    });  
    
    

     Signal.coneccion.on("BloquearComentario", onComentarioBloqueado)

     Signal.coneccion.on("BorrarComentario", onComentarioBorrado)

    let resaltando = false;
    function resaltarComentariosDeUsuario(usuarioId) {
        if(!$globalStore.usuario.esMod) return;
        if(resaltando) {
            comentarios.forEach(c => c.resaltado = false)
            comentarios = comentarios   
            items = comentarios
            resaltando = false
            return;
        }
        comentarios.forEach(c => {
            if(c.usuarioId == c.usuarioId) {
                resaltando = true;
            }
            c.resaltado = usuarioId == c.usuarioId
        })
        comentarios = comentarios
        items = comentarios
    }

    function tagCliqueado(e) {
        if(!diccionarioComentarios[e.detail]) return;
        if(modoTelefono && !$ajustesConfigStore.tagClasico) {
            e.preventDefault() 
            historialRespuestas = [[diccionarioComentarios[e.detail]]]
        }
        comentarios.forEach(c => c.resaltado = false)
        comentarios = comentarios
        items = comentarios
        diccionarioComentarios[e.detail].resaltado = true;
    }
    

    let comentarioUrl = window.location.hash.replace("#", "")

    async function irAComentario(comentarioId) {
        if(diccionarioComentarios[comentarioId]) {
            diccionarioComentarios[comentarioId].resaltado = true
            
            let comentarioDOM = document.getElementById(comentarioId)
            await tick()
            if(comentarioDOM) comentarioDOM.scrollIntoView({block:'center'})
        }
    }

    onMount(() =>irAComentario(comentarioUrl))
    irAComentario(comentarioUrl)

    let resaltadoIdUnico = false
    function idUnicoClickeado(e) {
        comentarios.forEach(c => {
            if(!resaltadoIdUnico) {
                c.resaltado = c.idUnico == e.detail
            } else {
                c.resaltado = false
            }
        });
        comentarios = comentarios
        items = comentarios
        resaltadoIdUnico = !resaltadoIdUnico
    }
        
    let carpetaMedia = false
    let historialRespuestas = []

    let cargarComentarios = false;
    onMount(async() => setTimeout( async () => {
        cargarComentarios = true
        await tick();
        irAComentario(comentarioUrl)
    }, 120))
   

  let items = comentarios
  let currentPage = 1
  let pageSize = 100
  $: paginatedItems = paginate({ items, pageSize, currentPage })
</script>
<CarpetaMedia {comentarios} bind:visible={carpetaMedia}></CarpetaMedia>
<div class="comentarios">
    <PilaRespuestas {diccionarioComentarios} {diccionarioRespuestas} historial = {historialRespuestas}/>
    {#if !$configStore.general.modoMessi || $globalStore.usuario.esMod}          
        <Formulario {hilo} on:comentarioCreado={cargarNuevosComentarios}/>
    {/if}

    <div class="contador-comentarios panel">       
        <Button style="width: auto;"
            dense icon><icon class="fe fe-edit"></icon>
            {comentarios.length}
        </Button>
        <Button style="width: auto;"
            dense icon><icon class="fe fe-eye"></icon>
            {hilo.vistas}
        </Button>
      
        {#if nuevosComentarios.length != 0}
            <div class="badge" style="font-size: 18px;height: auto;cursor: pointer;">
                <span on:click={cargarNuevosComentarios}>+ {nuevosComentarios.length}</span>
            </div>
        {/if}
        <div class="acciones-comentario">           
            <Button on:click={() => $comentariosStore.modoVivo = !$comentariosStore.modoVivo} 
                title="Comentarios en vivo (se cargan automaticamente)"
                dense icon> <div class="boton-modo-vivo" style={$comentariosStore.modoVivo?'background:var(--primary);':'background:white'}></div>
            </Button>
            <Button on:click={() => carpetaMedia = !carpetaMedia} 
                dense icon><icon class="fe fe-folder"></icon>
            </Button>
            {#if comentarios.length > 0}
                <a href="#{comentarios[comentarios.length -1].id}" style="margin: 0;">
                    <Button
                        dense icon><icon class="fe fe-arrow-down"></icon>
                    </Button>
                </a>
            {/if}
        </div>
    </div>
   
    <div class="lista-stickies">
        <Spinner cargando={!cargarComentarios}>     
            {#each stickies as comentario (comentario.id)}
                <li transition:fly|local={{ y: -50, duration: 250 }}>
                    <Comentario       
                          on:colorClick={(e) => resaltarComentariosDeUsuario(e.detail.usuarioId || '') }                
                          hilo={hilo} 
                          bind:comentario 
                          bind:comentariosDic = {diccionarioComentarios}
                          esSticky={true} 
                        respuetasCompactas={modoTelefono}
                        on:tagClickeado={tagCliqueado}
                        on:idUnicoClickeado={idUnicoClickeado}               
                        on:motrarRespuestas={(e)=>historialRespuestas=[diccionarioRespuestas[e.detail].map(c => diccionarioComentarios[c])]}
                    />
                </li>
            {/each}
         </Spinner>
    </div>
   
    <div class="lista-comentarios">
        <Spinner cargando={!cargarComentarios}>            
               <!-- -->
                    {#if !hilo.historial}
                        {#each paginatedItems as comentario (comentario.id)}
                            <li transition:fly|local={{y: -50, duration:250}}>
                                <Comentario 
                                    on:colorClick={(e) => resaltarComentariosDeUsuario(e.detail.usuarioId || '') } 
                                    hilo={hilo} 
                                    bind:comentario
                                    bind:comentariosDic = {diccionarioComentarios}
                                    respuetasCompactas={modoTelefono}
                                    on:tagClickeado={tagCliqueado}
                                    on:idUnicoClickeado={idUnicoClickeado}
                                    on:motrarRespuestas={(e)=>historialRespuestas=[diccionarioRespuestas[e.detail].map(c => diccionarioComentarios[c])]}
                                    />
                            </li>
                        {/each}
                    {:else}
                        {#each paginatedItems as comentario (comentario.id)}
                                <li transition:fly|local={{y: -50, duration:250}}>                           
                                    <a href="/hilo/{comentario.hiloId}#{comentario.id}">
                                    <Comentario 
                                        on:colorClick={(e) => resaltarComentariosDeUsuario(e.detail.usuarioId || '') } 
                                        hilo={hilo} 
                                        bind:comentario bind:comentariosDic = {diccionarioComentarios}
                                        respuetasCompactas={modoTelefono}
                                        on:tagClickeado={tagCliqueado}
                                        on:idUnicoClickeado={idUnicoClickeado}
                                        on:motrarRespuestas={(e)=>historialRespuestas=[diccionarioRespuestas[e.detail].map(c => diccionarioComentarios[c])]}
                                        />
                                    </a>
                                </li>
                        {/each}
                    {/if}
                <!-- -->
              {#if comentarios.length > pageSize}
                <LightPaginationNav
                    totalItems="{items.length}"
                    pageSize="{pageSize}"
                    currentPage="{currentPage}"
                    limit="{1}"
                    showStepOptions="{true}"
                    on:setPage="{(e) => currentPage = e.detail.page}"
                />
              {/if}
        </Spinner>
            
        </div>
        <div class="espacio-vacio"></div>

</div>

<style>
     .lista-stickies :global(.comentario-flotante .color) {
        box-shadow: none !important;
    }
    .lista-stickies :global(.sticky) {
        padding: 5px;
        margin-bottom: 4px;
        font-size: 0.9rem;
        border-color: var(--color5);
        border-style: solid;
    }
    .lista-stickies :global(.sticky .color) {
        width: 40px;
        height: 40px;
        box-shadow: 0px 0px 1px 1px var(--color6);
        margin: 5px 5px;
    }
    /*.lista-stickies :global(.sticky .color:after),
    .lista-stickies :global(.sticky .color:before) {
        display: none;
    }*/
    .lista-stickies :global(.sticky .media) {
        width: 25%;
    }
    .lista-stickies :global(.sticky .media.abierto) {
        width: 100%;
    }
    .espacio-vacio {
        height: 200px;   
        /* scroll-snap-align: center; */
    }
    .boton-modo-vivo {
        width:8px;
        height:8px;
        border-radius:8px;
        transition: background 0.2s;
    }
    @media(max-width: 600px) {
        .espacio-vacio {height: 24px;}
    }
    :global(.loader) {
        margin: 0 auto;
    }

</style>