<script>
    import { onMount } from 'svelte'
    import { createEventDispatcher } from 'svelte';
    import {Menuitem, Button, Icon, Ripple } from 'svelte-mui';
    import Menu from '../Menu.svelte'
    import comentarioStore from './comentarioStore'
    import { fly } from 'svelte/transition';
    
    import Tiempo from '../Tiempo.svelte'
    import globalStore from '../../globalStore';
    import Media from '../Media.svelte';
    import {abrir} from '../Dialogos/Dialogos.svelte'
    import { ComentarioEstado, CreacionRango, HiloEstado } from '../../enums';
    import selectorStore from '../Moderacion/selectorStore'
    import Captcha from "../Captcha.svelte";   
    import config from "../../config"
    import { writable } from 'svelte/store';
    import { debug } from 'svelte/internal';
    import Spinner from "../Spinner.svelte";
    import RChanClient from '../../RChanClient';
    import more from '../../icons/more-vertical.svg'

    
    export let comentario;
    export let hilo = { id:null };
    export let comentariosDic = {   };
    export let resaltado = false
    export let prevenirScroll = false
    export let esSticky = false;

    export let esRespuesta = false    
    comentario.estado = comentario.estado || ComentarioEstado.normal
    
    let base = window.location.hostname; 

    
    let el
    let mostrandoRespuesta = false
    let respuestaMostrada

    let mediaExpandido = false
    
    let windowsWidh = window.screen.width

    let visible = !$globalStore.comentariosOcultos.has(comentario.id)   
    
    
   
    let sentimentColor    
    
    let votoEncuesta
    let mostrarVoto = false
    var encuestaData = comentario.contenido.split('[-1_7-]');
    
    if(encuestaData.length == 2){
        comentario.contenido = encuestaData[1];
        votoEncuesta = encuestaData[0];
        mostrarVoto = true 
    }else{
        mostrarVoto = false 
    }   
    
    let dispatch = createEventDispatcher()

    let encriptedTk = localStorage.getItem('encriptedTk') 
    let eresTu = encriptedTk == comentario.encriptedTK  

    let userIdLocal =localStorage.getItem('userIdLocal')

    let puedeFijar = hilo.usuarioId == userIdLocal;

    setSentimentColor();

    onMount(() => {

        let respuestas = el.querySelectorAll(".restag")
        respuestas.forEach(r => {
            r.addEventListener("mouseover", () => mostrarRespuesta(r.getAttribute("r-id").trim()))
            r.addEventListener("mouseleave", ocultarRespuesta)
            r.addEventListener("click", (e) => {
                resaltarCliqueado(r.getAttribute("r-id").trim())
                if(prevenirScroll) {                  
                    e.preventDefault()
                }
            })
        })
    })

    function mostrarRespuesta(id) {
        if(!comentariosDic[id]) return
        mostrandoRespuesta = true
        respuestaMostrada = comentariosDic[id]
    }

    function resaltarCliqueado(id) {
        dispatch("tagClickeado", id)
    }

    function setSentimentColor() {

        if(comentario.sentiment >=3 ){
            sentimentColor = "springgreen"        
        }else if(comentario.sentiment < 3 && comentario.sentiment > 0){
            sentimentColor = "darkgreen"          
        }else if(comentario.sentiment ==0 ){
            sentimentColor = "white"         
        }
        else if(comentario.sentiment < 0 && comentario.sentiment >= -3){
            sentimentColor = "orange"
      
        }else if(comentario.sentiment < -3){
            sentimentColor = "red"
        
       } else{
            sentimentColor = "white"          
       }      
         
     }


    function ocultarRespuesta() {
        mostrandoRespuesta = false
    }

    function restImg(id) {       
      return RChanClient.restaurarImagen(id, 0);      
    }

    function toggle() {
        if(visible) {
            $globalStore.comentariosOcultos.set(comentario.id, true)
        } else {
            $globalStore.comentariosOcultos.delete(comentario.id)
        }
        $globalStore.comentariosOcultos = $globalStore.comentariosOcultos 
        visible = !visible
    }

    function seleccionar() {
        if(!$globalStore.usuario.esMod) return;
        selectorStore.selecionar(comentario.id)
    }

    if(!Array.isArray(comentario.respuestas)) comentario.respuestas = []

    function tagear(id) {
        if(!$comentarioStore.includes(`>>${comentario.id}\n`))
            $comentarioStore+= `>>${comentario.id}\n`
    }

    function esOp(comentarioId) {
        let  comentario = comentariosDic[comentarioId] || {esOp:false} //??quitado
        return comentario.esOp
    }

    function idUnicoColor() {
        let coloresPosibles = ['#7bd800', '#00d87e', '#006ad8','#3500d8', '#8500d8', '#d80096', '#737679', '#5d130b', '#ec64e2', '#ff5722']
        let n = comentario.idUnico.charCodeAt(0) + comentario.idUnico.charCodeAt(1) + comentario.idUnico.charCodeAt(2);
        return coloresPosibles[n % coloresPosibles.length - 1]
    }  

    
    let stickeando = false;
    async function togglesticky() {
        stickeando = true;
        try {           
            comentario.sticky = !comentario.sticky;  
            let res = await RChanClient.toggleSticky(comentario.id);                     
        } catch (error) {
            console.log(error);
        }
        stickeando = false;        
    }
  
</script>

<div bind:this={el}
    class:resaltado={comentario.resaltado  || resaltado|| $selectorStore.seleccionados.has(comentario.id)} 
    class="comentario {windowsWidh <= 400?"comentario-movil":""}" 
    class:eliminado = {comentario.estado == ComentarioEstado.eliminado}
    r-id="{comentario.id}" id="{comentario.id}{esRespuesta?'-res':''}{esSticky ? '-sticky' : ''}"
    style={(comentario.respuestas.length > 0)?'padding-bottom: 20px': ''}
    class:sticky={comentario.sticky} 
    >   
		
    {#if  comentario.respuestas.length > 0}
        <div class="respuestas-compactas"
            on:click={() => dispatch("motrarRespuestas", comentario.id)}>
            R: {comentario.respuestas.length}
        </div>
        <div  class="respuestas">
            {#each comentario.respuestas as r }
            <a href="#{r}" class="restag" r-id="{r}"
                on:mouseover={() => mostrarRespuesta(r)}
                on:mouseleave={ocultarRespuesta}
            >&gt;&gt;{r}{esOp(r)?'(OP)' : ''} </a> 
            {/each}
        </div    >
    {/if}
    {#if esSticky}
        <div class="color color-negro ns">
          <img src='https://{base}/Media/pinImage.png' alt='Pin'>            
        </div>
    {:else if  comentario.colors}
        <div id="z{comentario.id}" on:click={() => dispatch("colorClick", comentario)} 
            class="color color-{comentario.color} ns"
            class:dado={comentario.dados != undefined && comentario.dados != -1}>
            {#if comentario.nombre  == "sentinel" || comentario.comentarioDenunciado == true}                      
              <img style="height: 100%;font-size: small;" src='https://{base}/Media/sentinelImage.jpg'  alt='sentinel'> 
            {:else if  comentario.nombre  == "sentinel-B"}                    
               <img style="height: 100%;font-size: small;" src='https://{base}/Media/sentinelImage-b-2.jpg'  alt='sentinel'>  
            {:else if comentario.dados!= undefined && comentario.dados != -1}
                {comentario.dados}          
            {:else}                         
                {#if comentario.face == ""}                
                     ANON                
                {:else}
                   <img src={comentario.face} alt='face'>
                {/if}
            {/if}
        </div>    
        <div class="color sentiment ns svelte-104h8fq" style="background: {sentimentColor};">                   
        </div>
     {/if}
    <div class="header">
        {#if comentario.esOp} <span class="nick tag tag-op">OP</span>{/if}
        <span 
            on:click={seleccionar}
            class:nombreResaltado = {comentario.nombre} 
            class="nick nombre cptr"> 
                {#if comentario.nombre}
                    {comentario.nombre}
                {:else if eresTu }
                  <span  class="nick tag tag-tu">{'Yo'}</span>   
                {:else}   
                    {'Anon'}
                {/if}
        </span>
        
        {#if comentario.idUnico}
            <span 
                on:click={() => dispatch("idUnicoClickeado", comentario.idUnico)} 
                class="tag ns cpt idunico" style={`background:${idUnicoColor()};`}>
                {comentario.idUnico} <Ripple color="var(--primary)"/>
            </span>
        {/if}
        {#if comentario.usuarioId}
        <a href="/Moderacion/HistorialDeUsuario/{comentario.usuarioId}" style="color:var(--color6) !important">
            <span class="nick">{comentario.usuarioId.split("-")[0]}</span>
        </a>
        
        {/if}

        {#if comentario.bandera && comentario.nombre != "sentinel"
              && comentario.nombre != "sentinel-B" && comentario.nombre != "Obsidian"}  

			<span class="flag-size tag-flag {comentario.flag}" />          
          
        {/if}        

        <!-- <span class="rol tag">anon</span> -->
        <span style="margin-right: 8px;" class="id tag ns" on:click={() => tagear(comentario.id)}>{comentario.id}</span>
        
        {#if puedeFijar && !comentario.esOp}
            <Button                           
                disabled={stickeando}
                on:click={togglesticky}
                title={comentario.sticky ? "Unpin" : "Pin"}
            >
                <Spinner cargando={stickeando}>
                    <div class="sticky-info"><Icon size="17" path="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12M8.8,14L10,12.8V4H14V12.8L15.2,14H8.8Z" /></div>
                </Spinner
                >
            </Button>
         {/if}
        <span style="margin-right: 5px;" class="tiempo"><Tiempo date={comentario.creacion}/></span>

        <div>
            <Menu>
                <span slot="activador" on:click={() => mostrarMenu = true} class=""><i class="fe fe-more-vertical relative"></i></span>
                <li on:click={() => toggle()}>{visible?'Ocultar':'Mostrar'}</li>
                <li on:click={() => abrir.reporte(hilo.id || comentario.hiloId, comentario.id)}>Reportar</li>
                {#if $globalStore.usuario.esMod || $globalStore.usuario.esAuxiliar}
                    <hr>
                    {#if comentario.hiloId}
                        <a href="/Hilo/{comentario.hiloId}#{comentario.id}" style="color:white!important">
                            <Menuitem>Ir</Menuitem>
                        </a>
                    {/if}
                    <Menuitem on:click={() => abrir.ban(hilo.id || comentario.hiloId, comentario.id)} >Banear</Menuitem>
                    {#if comentario.estado == ComentarioEstado.normal}
                    <Menuitem on:click={() => abrir.eliminarComentarios([comentario.id])}>Eliminar</Menuitem>
                    {:else}
                        <Menuitem on:click={() => abrir.restaurarComentario(comentario.id)} >Restaurar</Menuitem>
                    {/if}      
                    <Menuitem on:click={() => restImg(comentario.id)} >Restaurar Media</Menuitem>             
                {/if}
            </Menu>
        </div>
    </div>
    
    <div class="respuestas">
    </div>    
    {#if visible}    
        {#if hilo.colors}
          <div class="contenido"class:mediaExpandido>
            {#if comentario.media}
                <Media media={comentario.media} bind:abierto={mediaExpandido}/>
            {/if}
            {#if comentario.comentarioDenunciado == true}
                <span style="color:red;font-weight: bold;" class="texto">
                    {@html comentario.contenido}
                </span>
            {:else}      
            {#if mostrarVoto}  
                <div class="voto svelte-1bl5dia">
                    Voto encuesta: {votoEncuesta}
                </div> 
            {/if}  
            <span class="texto">
                {@html comentario.contenido}
            </span> 
            {/if}
           
         </div>
        {:else}
                {#if !comentario.colors && !comentario.media}
                    <div class="contenido"class:mediaExpandido>
                        {#if comentario.media}
                            <Media media={comentario.media} bind:abierto={mediaExpandido}/>
                        {/if}
                        {#if comentario.comentarioDenunciado == true}
                        <span style="color:red;font-weight: bold;" class="texto">
                            {@html comentario.contenido}
                        </span>
                        {:else}   
                            {#if mostrarVoto}  
                            <div class="voto svelte-1bl5dia">
                                Voto encuesta: {votoEncuesta}
                            </div> 
                            {/if}    
                            <span class="texto" >
                                {@html comentario.contenido}
                            </span> 
                        {/if}             
                    </div>
                {:else}
                    <div class="contenido"class:mediaExpandido>
                        {#if comentario.media}
                            <Media media={comentario.media} bind:abierto={mediaExpandido}/>
                        {/if}   
                        {#if mostrarVoto}  
                            <div class="voto svelte-1bl5dia">
                                Voto encuesta: {votoEncuesta}
                            </div> 
                        {/if}                       
                        <span class="texto" >
                            {@html comentario.contenido}
                        </span>                        
                    </div>
                {/if}
            
        {/if}        
    {/if}
    {#if mostrandoRespuesta}
        <div transition:fly|local={{x: -50, duration:200}} class="comentario-flotante">           
            <svelte:self comentario = {respuestaMostrada}  esRespuesta={true}></svelte:self>            
        </div>
    {/if}
</div>
<style>
    .comentario {
        display: grid;
        gap: 10px;
        grid-template-columns: 50px calc(100% - 60px);
        grid-template-areas:
            "color header"
            "color respuestas"
            "color cuerpo";
        grid-template-rows: auto auto auto;
        padding: 10px;
        position: relative;
        margin-bottom: 8px;
        text-underline-position: under;
        transition: 0.1s background-color linear;
        position: relative;
    }

    .comentario .contenido {
        grid-area: cuerpo;
    }
    .mediaExpandido {
        display: flex;
        flex-wrap: wrap;
    }
    :global(.media) {
        margin-bottom: 10px;
    }

    .comentario .texto {
        white-space: pre-wrap;
        word-break: break-word;
        overflow: hidden;
        display: inline-block;
    }

    .respuestas {
        font-size: 0.7em;
        flex-wrap: wrap;
        display: flex;
        gap: 4px;
        flex-direction: row-reverse;
        justify-content: flex-end;
        grid-row: 2;
    }
    .respuestas-compactas {
        grid-row: 2;
        background: var(--color4);
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 5px 10px;
        border-radius: 10px 0 0 0px;
        font-size: 12px;
        color: var(--primary);
    }

    .contenido .media {
        float: left;
        margin-right: 10px;
        max-width: 50%;
    }

    .color {
        width: 50px;
        height: 50px;
        background: orange;
        grid-area: color;
        display: flex;
        align-items: center;
        padding: 2px;
        /* text-align: center; */
        font-stretch: condensed;
        /* border-radius: 10; */
        justify-content: center;
        font-weight: 600;
        /* color: #822f0047; */
        color: #ffffffe3;
        border-radius: 4px;
    }

    .snt{
        background: none;
    }
    .snt::after{
        background: none;
      
    } 

    /* Gorritos */
    /* .color-rojo::after, .color-multi::after, .comentarioMod .color::after, .color-navideño::after {
        content: '';
        background: url(/imagenes/colores/gorrito.png);
        position: absolute;
        top: 1px;
        left: 1px;
        height: 26px;
        width: 26px;
        background-size: 85%;
        background-repeat: no-repeat;
        transform: rotate(-4deg);
    } */

    .sentiment{
        border-style: groove;
        margin-top: 57px;
        width: 50px;
        height: 10px;
        font-size: smaller;
        font-family: sans-serif;
        border-radius: 0;
    }

    .comentario .header {
        grid-template-areas: color;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-size: 0.9em
    }

    .comentario .header span {
        margin-right: 10px;
    }

    .comentario .header .id {
        cursor: pointer;
    }

    .comentario .tag {
        background: #000000ad;
        padding: 0 5px;
        border-radius: 500px;
        display: flex;
        align-items: center;
        border-style: groove;
    }

    .comentario .tiempo {
        margin-left: auto;
        opacity: 0.5;
        font-size: 12px;
    }

    .tag-op {
        background: var(--color5) !important;
        border-style: groove;
    }

    .tag-flag{
        border-style: groove;
        width: 25px;
        border-radius: 8px;
    }

    .tag-tu {
        background: crimson !important;
        border-color: var(--primary);
        border-style: groove;
    }
    .comentario:hover {
        background: var(--color4);
    }

    .resaltado {
        background: var(--color4)!important;
    }
    .eliminado {
        border-left: solid 2px red !important;
    }

    .color-rojo {background: #dd3226;}
    .color-verde {background: #53a538;}
    .color-amarillo {background: #ffc400;}
    .color-azul {background: #00408a;}
    .color-rosa {background: #ff74c1;}
    .color-negro {background: #000000;}
    .color-marron {background: #492916;}
    .color-white {background: white;color: black;}
    .color-silver {background: slategray;border: lightslategray; border-style: groove;}

    .color-multi {
        background: linear-gradient(#ffc400    25%, #00408a  25%, #00408a  50%, #53a538   50%, #53a538   75%, #dd3226  75%, #dd3226  100%);
        animation: multi .3s infinite;
    }

    .color-invertido {
        background: linear-gradient(orange    25%, pink  25% , pink  50%, cyan    50%, cyan   75%, blue  75%, blue  100%);
        animation: invertido reverse .3s infinite;
    }


    @keyframes invertido {
        20%  { background: linear-gradient(blue 25%, orange  25%, orange  50%, pink 50%, pink 75%, cyan 75%, cyan 100%);}
        60%  { background: linear-gradient(cyan 25%, blue 25%, blue 50%, orange  50%, orange  75%, pink 75%, pink 100%);}
        80%  { background: linear-gradient(pink 25%, cyan 25%, cyan 50%, blue 50%, blue 75%, orange  75%, orange  100%);}
        100% { background: linear-gradient(orange  25%, pink 25%, pink 50%, cyan 50%, cyan 75%, blue 75%, blue 100%);}
    }

    
    @keyframes multi {
        20%  { background: linear-gradient(#dd3226 25%, #ffc400 25%, #ffc400 50%, #00408a 50%, #00408a 75%, #53a538 75%, #53a538 100%);}
        60%  { background: linear-gradient(#53a538 25%, #dd3226 25%, #dd3226 50%, #ffc400 50%, #ffc400 75%, #00408a 75%, #00408a 100%);}
        80%  { background: linear-gradient(#00408a 25%, #53a538 25%, #53a538 50%, #dd3226 50%, #dd3226 75%, #ffc400 75%, #ffc400 100%);}
        100% { background: linear-gradient(#ffc400 25%, #00408a 25%, #00408a 50%, #53a538 50%, #53a538 75%, #dd3226 75%, #dd3226 100%);}
    }

    .comentarioMod  {
        border-top: solid 2px;
        animation: borde-luz 0.3s infinite alternate-reverse;
        
    }
    .voto{
        font-weight: 700;
        word-break: break-word;
        overflow: hidden;
        color: --color3;
        color: var(--color6);
        background: var(--color4);margin-bottom: 10px;
    }

    .comentarioMod >.color {
        animation: luces 0.3s infinite alternate-reverse;
        color: white !important;
    }
    .comentarioAuxiliar  {
        border-top: solid 2px;
        animation: borde-serenito 0.3s infinite alternate-reverse;
        
    }
    .comentarioAuxiliar > .color{
        background: url(/imagenes/serenito.gif);
        background-size: 161px;
        color: transparent;
        background-position: center;
    }

    .nombreResaltado {
        color: var(--color6);
        font-weight: bold;
    }


    @keyframes borde-serenito {
        0% {border-color: rgb(255, 136, 0);}
        100% {border-color: transparent;}
    }
    @keyframes borde-luz {
        0% {border-color: red;}
        100% {border-color: blue;}
    }
    @keyframes luces {
        0% {
            background: red;
            border-color: red;

        }
        100% {
            background: blue;
            border-color: blue;

        }
    }

    .cptr {
        cursor: pointer;
    }
.dado {
    font-size: 2rem;
    font-family: 'terminator';
}

.idunico:hover {
   color: var(--primary)
}
@media (max-width: 600px) {
  .comentario :global(.restag), .respuestas-compactas {
      font-weight: bold !important;
  }
  .comentario :global(a) {font-weight: bold !important;}
}
    /* @media(max-width >600px) {} */

    /* .comentario-movil :glo.media {
  max-width: 100%;
  width: 100%;
}
.comentario-movil .color {
  height: 30px;
  position: relative;
  top: -8px;
  left: -8px;
}
.comentario-movil {
  grid-template-areas:
  "color header"
  "respuestas respuestas"
  "cuerpo cuerpo";
} */

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.ar{
  background-image: url('/Media/a7a46aa50d3a2bb5ae7b0de0e1caf38b.png');
}

.py{
  background-image: url('/Media/80573481177fdd33ecd86de6b8f65419.png');
}

.cl{
  background-image: url('/Media/d8599a5a26b3a5b811435ce40f0dc85d.png');
}

.bo{
  background-image: url('/Media/ae553b2afc8e99b0cf0ff25a6d5fb6c0.png');
}

.mx{
  background-image: url('/Media/9645cea2f1131e584743db436038f9f3.png');
}

.uy{
  background-image: url('/Media/40bb51659ad0b302b8b919d4cbb64213.png');
}

.pe{
  background-image: url('/Media/e2e9c8533336bc265569b387deba038a.png');
}

.ca{
  background-image: url('/Media/5b0fda4b9567357486073ada898edceb.png');
}

.co{
  background-image: url('/Media/0c5dac095efe92758e60a49938d2b8ff.png');
}

.ve{
  background-image: url('/Media/3662ac7c082ad67f00b3497d19a016ac.png');
}

.es{
  background-image: url('/Media/5fd56c3a56e669f0bf55313516386ef7.png');
}

.us{
  background-image: url('/Media/79c05fec40bea2dbf10ff2a322afe38c.png');
}

.br{
  background-image: url('/Media/5cc52563d2ca4c51e31df5ef8980ef50.png');
}

.hn{
  background-image: url('/Media/3806263aac732a75ad89aaa3395ca9fa.png');
}

.pa{
  background-image: url('/Media/7b40ceee150afa0ad44f91c5a955bf66.png');
}

.gt{
  background-image: url('/Media/91068f7a12a60379402fd1c25beb6bfd.png');
}

.cr{
  background-image: url('/Media/a9bd9045ee0862bf54d700b056df610d.png');
}
.sv{
  background-image: url('/Media/b639011a285090de4c1bf1fe38b84f5d.png');
}

.ec{
  background-image: url('/Media/5b398d6969609972639edf83280c66fd.png');
}

.it{
  background-image: url('/Media/9d3aaca8260edf6124fac3502847ab78.png');
}

.do{
  background-image: url('/Media/a18cdff5d202a2db10039d33c1e53da1.png');
}

.flag-size{
    min-width: 30px;
    min-height: 21px;   
    background-size: contain;   
    transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.8,0.8);
}
</style>