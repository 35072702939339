<script>
    import {Button} from 'svelte-mui'
    import {formatearTiempo, formatearTimeSpan} from '../../helper'
    const ban = window.model.ban

</script>
<div class="banneado">
    <div>
        <div class="mensaje">           
            <h2 style="margin-bottom:10px">Fuiste <span style="color:red">Baneado</span> por romper las <a style="color:var(--primary)" href="/reglas.html">reglas</a> de Ufftopia {ban.tipo== 0? `en el hilo "${ban.hilo}"`:`en un comentario en el hilo "${ban.hilo}"`}</h2>
            <h3 style="padding-top: 2%;">Aclaracion: <strong>{ban.aclaracion ||" "}</strong> </h3>
            <p>Motivo: {ban.motivo}</p>
            <p>Fecha: {formatearTiempo(ban.creacion)}</p>
            <p>Duracion: {formatearTimeSpan(ban.duracion)}</p>
            <p>Expira el: {formatearTiempo(ban.expiracion)}</p>
            <p>Id del ban: {ban.id}</p>            
        </div>  
    </div>
</div>
<style>
    .banneado {
        margin-left: 5px;
        margin-right: 5px;       
        margin-top: 115px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 425px;           
     }
     .mensaje{
        font-size: larger;
        font-weight: bold; 
        padding-top: 35px;
        padding-left: 13px;
        padding-bottom: 18px;
     }
   
</style>