<script>
    import MediaType from '../MediaType'
    import {Button, ButtonGroup, Icon} from 'svelte-mui'
    import { onDestroy, onMount } from 'svelte';   
    import {Dialog} from 'svelte-mui'
	
    export let compacto = false
    export let commands = false  

    export let media = {
        archivo: null,
        link: ""
    }
    export let videoUrl = null
    
    let base = window.location.hostname; 

    let vistaPreviaYoutube = ""
    let visible = false
    let menuLink = false
    let archivoBlob = null
    let input = null
    let mediaType = MediaType.Imagen
    let el
    
    let modalTipos = false

    let inputLink = ""
    let estado = "vacio" // importarLink | cargado
    const youtubeRegex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/
    let commandsPanel 
    async function actualizarArchivo() {
        if (input.files && input.files[0]) {
            compacto = false
            visible = true           
            archivoBlob = await getBlobFromInput(input)
            media.archivo = input.files[0]          
            if(input.files[0].type.indexOf('image') != -1) {                
                mediaType = MediaType.Imagen               
            } else if(input.files[0].type.indexOf('video') != -1) { 
                mediaType = MediaType.Video
            }  else if(input.files[0].type.indexOf('application/pdf') != -1) { 
                mediaType = MediaType.Pdf
                archivoBlob = 'https://'+base+'/Media/pdfImage.jpg'              
            }     
        }        
    }

    let blob
    async function getBlobFromInput(input) {
       
        if (!(input.files && input.files[0])) return null;

         
        return new Promise((resolve, reject) => {                 
            if (!(input.files && input.files[0])) return null;
            let blob
            let reader = new FileReader()
            reader.onload = function (e) {
                blob = e.target.result
                resolve(blob)
            }                 
            reader.readAsDataURL(input.files[0])           
        })
    }

    function getDailyMotionId(url) {
        var m = url.match(/^.+dailymotion.com\/((video|hub)\/([^_]+))?[^#]*(#video=([^_&]+))?/);
        return m ? m[5] || m[3] : null;
    }

    function getBitchuteId(url) {
        var link = inputLink.split('/')        
        return link ? link[4] : null;
    }

    function getSpotifyId(url) {
    
        var spotify ="https://open.spotify.com/"
        var link = inputLink.split('?')
         if(!link[0].startsWith(spotify)){
                return null;
         }
        
        return link ? link[0] : null;
    }

    function getJdoodleId(url) {
    
    //"3xzpvtg99"
    
     if(url.length != 9){
            return null;
     }
    
    return url ? url : null;
   }

    function getTikTokId(url) {
        var link = inputLink.split('/')        
        return link ? link[5] : null;
   }

   function getCoubId(url) {  
        var link = inputLink.split('/')          
        return link ? link[4] : null;
   }
   function getOkRusiaId(url) {  
        var link = inputLink.split('/')          
        return link ? link[4] : null;
   }

    async function importarVideo() {        
        try {        
        if(inputLink.startsWith('tv-')){  
            //tv-https://latelegratis.com/TV/latinos/tcm/tv-https://tvnotiblog.com/wp-content/uploads/2011/12/logo-tcm-768x432.jpg          
            //tv-https://www.youtube.com/watch?v=gLzcS8KBqbY/tv-https://i.ytimg.com/vi/gLzcS8KBqbY/maxresdefault.jpg
            var link = inputLink.split('tv-')            
            mediaType = MediaType.Tv
            vistaPreviaYoutube = link[2]
            videoUrl = link[1]
            archivoBlob = link[2]
            media.link = inputLink
            estado = "cargado"
            compacto = false
            visible = true
        }else if(inputLink.includes('youtube') || inputLink.includes('youtu.be')){
            let id = inputLink.match(youtubeRegex)          
            if(!id) {
                inputLink = "Link inválido"
                return
            }
            mediaType = MediaType.Youtube
            vistaPreviaYoutube = `https://img.youtube.com/vi/${id[1]}/hqdefault.jpg`
            videoUrl = inputLink
            archivoBlob = `https://img.youtube.com/vi/${id[1]}/hqdefault.jpg`
            media.link = videoUrl
            estado = "cargado"
            compacto = false
            visible = true
        }else if(inputLink.startsWith('https://www.dailymotion.com')){

            let id = getDailyMotionId(inputLink)           
            if(!id) {
                inputLink = "Link inválido"
                return
            }
            mediaType = MediaType.Dailymotion

            let preview
            let dayliApi = `https://api.dailymotion.com/video/${id}?fields=thumbnail_medium_url,thumbnail_small_url,thumbnail_large_url`
            fetch(dayliApi)
                .then(response => response.json())
                .then(data => {
                    preview = data.thumbnail_large_url;  
                    vistaPreviaYoutube = preview
                    videoUrl = inputLink
                    archivoBlob = preview
                    media.link = videoUrl
                    estado = "cargado"
                    compacto = false
                    visible = true
                    console.log('mal');               
                }).catch(error => {
                console.log(error);
                    return [];
             });            
         }else if(inputLink.startsWith('https://www.bitchute.com')){
            mediaType = MediaType.Bitchute  
            let id = getBitchuteId(inputLink)           
            if(!id) {
                inputLink = "Link inválido" 
                return
            } 
            videoUrl = inputLink
            archivoBlob = 'https://www.bitchute.com/embed/'+id+'/'
            media.link = videoUrl
            estado = "cargado"
            compacto = false
            visible = true  
         }
         else if(inputLink.startsWith('https://archive.org/')){
            mediaType = MediaType.Bitchute  
            let id = getBitchuteId(inputLink)           
            if(!id) {
                inputLink = "Link inválido" 
                return
            } 
            videoUrl = inputLink
            archivoBlob = 'https://archive.org/embed/'+id+'/'
            media.link = videoUrl
            estado = "cargado"
            compacto = false
            visible = true   
         }
         else if(inputLink.startsWith('https://www.tiktok.com/')){
            mediaType = MediaType.TikTok  
            let id = getTikTokId(inputLink)           
            if(!id) {
                inputLink = "Link inválido" 
                return
            } 
            videoUrl = inputLink
            archivoBlob = 'https://www.tiktok.com/embed/'+id+'/'
            media.link = videoUrl
            estado = "cargado"
            compacto = false
            visible = true   
         }
         else if(inputLink.startsWith('https://coub.com/')){
            mediaType = MediaType.Coub  
            let id = getCoubId(inputLink)           
            if(!id) {
                inputLink = "Link inválido" 
                return
            } 
            videoUrl = inputLink
            archivoBlob = 'https://www.coub.com/embed/'+id+'/'
            media.link = videoUrl
            estado = "cargado"
            compacto = false
            visible = true   
         }
         else if(inputLink.startsWith('https://ok.ru/')){
            mediaType = MediaType.OkRusia  
            let id = getOkRusiaId(inputLink)           
            if(!id) {
                inputLink = "Link inválido" 
                return
            } 
            videoUrl = inputLink
            archivoBlob = 'https://ok.ru/videoembed/'+id+'/'
            media.link = videoUrl
            estado = "cargado"
            compacto = false
            visible = true   
         }
        } catch (err) {
              alert(err.message);
        }
    }

    async function importarSpotify() {
        mediaType = MediaType.Spotify  
            let id = getSpotifyId(inputLink)           
            if(!id) {
                inputLink = "Link inválido" 
                return
            } 
            videoUrl = id
            archivoBlob = 'https://'+base+'/Media/spotifyImage.jpg'   
            media.link = videoUrl
            estado = "cargado"
            compacto = false
            visible = true   

    }

    async function importarJdoodle() {
        mediaType = MediaType.Jdoodle  
            let id = getJdoodleId(inputLink)           
            if(!id) {
                inputLink = "Link inválido" 
                return
            } 
            videoUrl = id
            archivoBlob = 'https://'+base+'/Media/jdoodleImage.png'   
            media.link = videoUrl
            estado = "cargado"
            compacto = false
            visible = true   

    }

    export function removerArchivo() {
        visible = false
        compacto = true
        media.archivo = null
        media.link = ''
        archivoBlob = null
        input.value = ''
        inputLink = ''
        mediaType = MediaType.Imagen
        estado = "vacio"       
    }
    
    onDestroy(() => {
        media.archivo = null
        archivoBlob = null
    })

    function handleInputChange(event){
        inputLink = event.target.value;        
        importarVideo()
    }
  
    onMount(() => {
        window.addEventListener('paste', e => {           
            input.files = e.clipboardData.files;        
            actualizarArchivo()          
        });
    })

   
</script>
<input 
    name="archivo" 
    on:change={actualizarArchivo}
    type="file" 
    id="hilo-input" 
    style="position: absolute; top:-1000px"
    bind:this={input}

>
<div 
    bind:this={el} 
    class:compacto class="video-preview media-input ">
    {#if mediaType == MediaType.Video && media.archivo}      
        <video style="max-height: 290px; max-width: 598px;" src="{archivoBlob}" controls>
            <track kind="captions">
        </video>
        
    {:else if visible && mediaType == MediaType.Dailymotion }   
       <iframe title="preview" src="{archivoBlob}" style="height: 100%;"></iframe>	  
    {:else if visible && mediaType == MediaType.Bitchute }     
       <iframe title="preview" src="{archivoBlob}" style="height: 105%;width:106%"></iframe>
       {:else if visible && mediaType == MediaType.ArchiveOrg }     
       <iframe title="preview" src="{archivoBlob}"></iframe>	  
       {:else if visible && mediaType == MediaType.TikTok }   
       <iframe title="preview" src="{archivoBlob}" style="height: 100%;"></iframe>	
       {:else if visible && mediaType == MediaType.Coub }   
       <iframe title="preview" src="{archivoBlob}" style="height: 100%;"></iframe>	
       {:else if visible && mediaType == MediaType.OkRusia }   
       <iframe title="preview" src="{archivoBlob}" style="height: 100%;"></iframe>	
       {:else if visible && mediaType == MediaType.Jdoodle } 
        <img src='{archivoBlob || media}' alt="preview" style="height: 100%;width:100%">  
    {:else if visible}   
	     <img src='{archivoBlob || media}' alt="preview" style="height: 100%;width:100%">
   {/if}
  
   {#if estado=="importarLink"}
    <div class="link-input">
        <input formnovalidate type="text" on:input={handleInputChange} bind:value={inputLink} placeholder="Link/Clipboard Image">
            <Button  formnovalidate outlined shaped={true} on:click={importarVideo}> 
                    <icon>OK</icon>
            </Button>
            <Button  style="background-image: -webkit-linear-gradient(92deg,violet,#3f00ff);" class="color-type" formnovalidate outlined shaped={true} on:click={() => modalTipos = true }> 
                <icon>Tipos</icon>
            </Button>
            <Button  formnovalidate outlined shaped={true} on:click={() => estado = "vacio" }> 
                    <icon>x</icon>
            </Button>
           
    </div>
    <Dialog  visible={modalTipos} modal={true}>
        <div slot="title">Medios Aceptados</div>
        <ul>
            <li>Youtube</li>
            <li>TikTok</li>
            <li>Bitchute</li>
            <li>Dailymotion</li>
            <li>Internet-Archive</li>
            <li>Coub</li>
            <li>Ok Rusia (Otakustv, etc...)</li> 
            <div class="actions center">
                <Button color="primary" on:click={() => modalTipos = false}>Ok</Button>
            </div>
        </ul>
    </Dialog>
    {/if}
    {#if estado=="importarLinkSpotify"}
    <div class="link-input">
        <input formnovalidate type="text" bind:value={inputLink} placeholder="Spotify link">
            <Button  formnovalidate outlined shaped={true} on:click={importarSpotify}> 
                    <icon>OK</icon>
            </Button>
            <Button  formnovalidate outlined shaped={true} on:click={() => estado = "vacio" }> 
                    <icon>x</icon>
            </Button>
    </div>
    
    {/if}
    {#if estado=="importarLinkJdoodle"}
    <div class="link-input">
        <input formnovalidate type="text" bind:value={inputLink} placeholder="código (ej 3xzq7fypr) de onecompiler.com">
            <Button  formnovalidate outlined shaped={true} on:click={importarJdoodle}> 
                    <icon>OK</icon>
            </Button>
            <Button  formnovalidate outlined shaped={true} on:click={() => estado = "vacio" }> 
                    <icon>x</icon>
            </Button>
    </div>
    
    {/if}

    {#if commandsPanel}
    <div class="link-input">
        <div style='background: black;height: fit-content; width: 100%;color:var(--color5);'>
            <div>[Comandos]</div>          
            <div>&gt;&gt;idunico</div>
             <div>&gt;&gt;dados</div>
             <div>&gt;&gt;banderas</div>        
             <div>&gt;&gt;r50</div>
             <div>&gt;&gt;sincolores</div>       
        </div>    
        <Button  formnovalidate outlined shaped={true} on:click={() => commandsPanel = false }> 
            <icon>x</icon>
        </Button>    
    </div>
    {/if}
    {#if !media.archivo && estado=="vacio" && !commandsPanel}
        <span class="opciones">          
            <div variant="text" color="primary" aria-label="text primary">
                <Button formnovalidate on:click={() => input.click()} outlined shaped={false} on:click={() => true}> 
                     <icon class="fe fe-upload"></icon>
                </Button>
                <Button formnovalidate  outlined shaped={false} on:click={() => estado="importarLink"}> 
                     <icon class="fe fe-youtube"></icon>
                </Button>
                <Button formnovalidate style="padding: 6px;" on:click={() => input.click()} outlined shaped={false} on:click={() => true}> 
                   <div>pdf</div>
               </Button>
               <Button formnovalidate style="padding: 6px;"  outlined shaped={false} on:click={() => estado="importarLinkSpotify"} > 
                <div>Spotify</div>
                </Button>
                <Button formnovalidate style="padding: 6px;"  outlined shaped={false} on:click={() => estado="importarLinkJdoodle"} > 
                    <div>code</div>  
                    </Button>
                {#if commands}                 
                <Button formnovalidate  outlined shaped={false} on:click={() =>commandsPanel= true}> 
                    <icon class="fe fe-terminal"></icon>
               </Button>
               {/if}
            </div>
        </span>
        {/if}
    {#if media.archivo || media.link}
        <Button formnovalidate class="cancelar" on:click={removerArchivo} icon outlined shaped={true} on:click={() => true}> 
            <icon class="fe fe-x"></icon>
        </Button>
    {/if}  
    
</div>



<style>
    .opciones {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;
        height: fit-content;
        margin-top: auto;
    }
    .media-input :global(.cancelar) {
        margin-left: auto;
        align-self: baseline;

    }

    .media-input {
        align-items: flex-end;
        min-height: 64px;
    }

    .compacto {
        height: auto;
        width: 100%;
        margin-left: 0;
        max-height: 300px;
    }

    .link-input {
        height: fit-content;
        display: flex;
        width: 100%;
    }

    video {
        max-height: 600px;
        max-width: 600px;
    }

    .media-input :global(.cancelar ){
        position: absolute;
        right: 16px;
        top: 16px;
    }
</style>