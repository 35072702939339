<script>
    import { Button} from 'svelte-mui';
    import Media from "../Media.svelte";
    export let hilo = null

    let mediaExpandido= false 
  
</script>
{#if hilo.nuevoUsuario}   
    <div><span style="border-radius: 500px;" class="animate-charcter tag tag-tu svelte-1uqkc9x">Nuevo usuario</span></div>             
{/if}  

<div class="cuerpo markdown-body" class:mediaExpandido>     
    <Media media={hilo.media} bind:abierto={mediaExpandido}/>  

    <div style="margin-bottom: 16px;">            
            {#if hilo.idUnico}   
                <span  class="nick tag tag-comando svelte-1uqkc9x">&gt;&gt;Id Unico</span>
            {/if}
            {#if hilo.dados}    
                <span  class="nick tag tag-comando svelte-1uqkc9x">&gt;&gt;Dados</span>
            {/if}    
            {#if hilo.bandera}    
               
                {#if hilo.country !== ""}                
                    <span class="flag-size tag-flag {hilo.country}" />   
                {/if}
                
                 <span  class="nick tag tag-comando svelte-1uqkc9x">&gt;&gt;Banderas</span> 
                
            {/if}           
            {#if hilo.reset200}  
                <span  class="nick tag tag-comando svelte-1uqkc9x">&gt;&gt;Reset 50</span> 
            {/if}
            {#if !hilo.colors}  
                <span  class="nick tag tag-comando svelte-1uqkc9x">&gt;&gt;Sin colores</span> 
            {/if}
            {#if hilo.encuesta}  
                <span  class="nick tag tag-comando svelte-1uqkc9x">Encuesta </span> 
            {/if}
    </div> 
    
    <h1>{hilo.titulo}</h1>    
    <div class="texto" style="white-space: pre-wrap;word-break: break-word;margin-top: 2%;">{@html hilo.contenido}</div>
</div>

<style>
    .mediaExpandido {
        display: flex;
        flex-wrap: wrap;
    }

    .mediaExpandido h1, .mediaExpandido .texto {
        width: 100%;
    }

    :global(.media) {
        float: left;
        margin-right: 10px;
        max-width: 50%;
        overflow: hidden;
        clear: both;
    }
    .cuerpo {
        padding: 0 10px;
        direction: rtl;  
    }
    .cuerpo * {
        direction: ltr;
    }
    @media (max-width: 992px) {
	.cuerpo :global(.media) {
		max-width: 100%;
		width: 100% !important;
		float: none
	}
    .cuerpo :global(a) {
        color: var(--primary) !important;
    }

    @media (max-width: 600px) {
        h1{
            font-weight: bold !important;
        }
    }   
}

.tag-comando {
        background: var(--color1) !important;
        border-color: var(--color6);
        border-style: groove;        
        border-radius: 500px;       
        align-items: center;
        border-style: groove;
        color: var(--color5);
        vertical-align: super;
        font-weight: 700;
    }    

    .tag-country {
        background: var(--color1) !important;
        border-color: var(--color6);
        border-style: groove;        
        border-radius: inherit;   
        align-items: center;
        border-style: groove;
        color: var(--color-texto1);
        vertical-align: -webkit-baseline-middle;
        font-weight: 700;
    }    

.animate-charcter
    {
   
    background-image: linear-gradient(
        -225deg,
        #231557 0%,
        #44107a 29%,
        #ff1361 67%,
        #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
     
    }

    @keyframes textclip {
        to {
            background-position: 200% center;
        }
    }

.ar{
  background-image: url('/Media/a7a46aa50d3a2bb5ae7b0de0e1caf38b.png');
}

.py{
  background-image: url('/Media/80573481177fdd33ecd86de6b8f65419.png');
}

.cl{
  background-image: url('/Media/d8599a5a26b3a5b811435ce40f0dc85d.png');
}

.bo{
  background-image: url('/Media/ae553b2afc8e99b0cf0ff25a6d5fb6c0.png');
}

.mx{
  background-image: url('/Media/9645cea2f1131e584743db436038f9f3.png');
}

.uy{
  background-image: url('/Media/40bb51659ad0b302b8b919d4cbb64213.png');
}

.pe{
  background-image: url('/Media/e2e9c8533336bc265569b387deba038a.png');
}

.ca{
  background-image: url('/Media/5b0fda4b9567357486073ada898edceb.png');
}

.co{
  background-image: url('/Media/0c5dac095efe92758e60a49938d2b8ff.png');
}

.ve{
  background-image: url('/Media/3662ac7c082ad67f00b3497d19a016ac.png');
}

.es{
  background-image: url('/Media/5fd56c3a56e669f0bf55313516386ef7.png');
}

.us{
  background-image: url('/Media/79c05fec40bea2dbf10ff2a322afe38c.png');
}

.br{
  background-image: url('/Media/5cc52563d2ca4c51e31df5ef8980ef50.png');
}

.hn{
  background-image: url('/Media/3806263aac732a75ad89aaa3395ca9fa.png');
}

.pa{
  background-image: url('/Media/7b40ceee150afa0ad44f91c5a955bf66.png');
}

.gt{
  background-image: url('/Media/91068f7a12a60379402fd1c25beb6bfd.png');
}

.cr{
  background-image: url('/Media/a9bd9045ee0862bf54d700b056df610d.png');
}
.sv{
  background-image: url('/Media/b639011a285090de4c1bf1fe38b84f5d.png');
}

.ec{
  background-image: url('/Media/5b398d6969609972639edf83280c66fd.png');
}

.it{
  background-image: url('/Media/9d3aaca8260edf6124fac3502847ab78.png');
}

.do{
  background-image: url('/Media/a18cdff5d202a2db10039d33c1e53da1.png');
}

.flag-size{
    min-width: 30px;
    min-height: 21px;   
    background-size: contain;   
    transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -webkit-transform: scale(0.8,0.8);

}

.tag-flag{
        border-style: groove;
        width: 25px;
        border-radius: 8px; display: inline-block;
            width: 50px; /* Ancho del span */
            height: 30px; /* Alto del span */
         
            background-size: cover;
    }
</style>