<script>
    import Dialogo from '../Dialogo.svelte'    
    import RChanClient from '../../RChanClient';   
   
    let mensaje = ""    
    $: onChange(visible);
    
    function onChange(visible) {
      if(!visible){        
        mensaje = ""
      }
    }
   
    export let  visible = false     
    mensaje = (visible)? mensaje:""

</script>

<Dialogo    
    bind:visible={visible} 
    textoActivador="Hasta 25 caracteres" 
    titulo="Mensaje Global"
    accion={() => RChanClient.EnviarMensajeGlobal(mensaje)}>
    <slot slot="activador">        
    </slot>   
    <div slot="body">             
        <p>Enviar Mensaje Global </p> 
        <p>*Recordá que los Mensajes Globales están Bajo Moderación y son motivo de baneo por incumplir las reglas del sitio.</p> 
        <textarea placeholder="*Mensaje" bind:value={mensaje}></textarea>
    </div>
</Dialogo>
