import {localStore} from '../../localStore'
let configInicial = {
    fondoAburrido: false,
    colorFondo: "#101923",
    usarImagen: false,   
    modoCover: true,
    scrollAncho: false,
    tagClasico: false,
    palabrasHideadas: "",
    flip:false
}

// Cargo configuracion gudardada
export default localStore('ajustes_config', configInicial)