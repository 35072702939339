<script>
    import { fade, blur, fly } from 'svelte/transition';
    import { Button, Ripple, Checkbox} from 'svelte-mui';
    import config from "../../config";
    import RChanClient from '../../RChanClient';
    import ErrorValidacion from '../ErrorValidacion.svelte';
    import MediaType from '../../MediaType'
    import MediaInput from '../MediaInput.svelte';
    import Captcha from '../Captcha.svelte';
    import Spinner from '../Spinner.svelte';
    import globalStore from '../../globalStore';
    import FormularioEncuesta from './FormularioEncuesta.svelte';
    import { tick } from 'svelte';
    import Switch from '../Switch.svelte';
    import { onMount } from 'svelte'
    
    export let mostrar = false
    
    let titulo = ""
    let categoria = "-1"
    let contenido = ""
    let media
    let captcha = ""

    let hashtags = ""
   
    let placeholderTexto = " Escribí un texto..."

    let encuesta = new Set([])
    $: encuestaArray = [...encuesta]   
    
    let mostrarRango = false
    let mostrarNombre = false
    
    let cargando = false
    
    let idUnico = false
    let dados = false
    
    
    let error = null

    let categoriaAyuda =  config.categorias.filter(c => c.groupId == 1)
    let categoriaCultura =  config.categorias.filter(c => c.groupId == 2)
    let categoriaEntretenimiento =  config.categorias.filter(c => c.groupId == 3 && c.nombre != "Canales")
    let categoriaErotico =  config.categorias.filter(c => c.groupId == 9)
    let categoriaHistorias =  config.categorias.filter(c => c.groupId == 6)
    let categoriaInternet =  config.categorias.filter(c => c.groupId == 8)
    let categoriaPolitica =  config.categorias.filter(c => c.groupId == 5)
    let categoriaTecnologia =  config.categorias.filter(c => c.groupId == 4)
    let categoriaUfftopia =  config.categorias.filter(c => c.groupId == 7)
    let categoriaVehiculo =  config.categorias.filter(c => c.groupId == 10)
    let categoriaXXX =  config.categorias.filter(c => c.groupId == 11)
    let categoriaNaturaleza =  config.categorias.filter(c => c.groupId == 12)
    
    
    async function crear() {
        filtro = "";    
        if($globalStore.usuario.esMod && mostrarNombre){

            if (!confirm('ATENCION ESTAS POR FIRMAR COMO ADMIN, DESEAS CONTINUAR?')) {
               return;            
            }
        }
        cargando = true
        encuesta = encuesta       
        try {
            let r = null 
            if(!$globalStore.usuario.esMod){
                    r = await  await RChanClient
                        .crearHilo(idUnico,dados,titulo, 
                            categoria,
                            contenido, 
                            media.archivo, 
                            media.link, 
                            captcha,
                            [...encuesta],
                            hashtags)
                } else {
                    r = await  await RChanClient
                        .crearHilo(idUnico,dados,titulo, 
                            categoria,
                            contenido, 
                            media.archivo, 
                            media.link,
                            captcha,
                            [...encuesta], 
                            hashtags,
                            mostrarNombre, 
                            mostrarRango)
                }
            if (r.status == 201) {
                    window.location.replace(r.headers.location)
                }
        } catch (e) {
           error = e.response.data
        }
        cargando = false
    }
    
    
    let escribiendoRedactazo = false
    let textarea2
    
    async function onTeaxtAreaFocus(params) {
       escribiendoRedactazo = true
        await tick()
        textarea2.focus()
    }    

    function titleTextCounter(event) { 

        var element = document.getElementById("myTitulo");
        if (titulo.length > 80){        
            element.style.backgroundColor = "red";		
        }
        else{  
            element.style.backgroundColor = "var(--color4)";
        }
    }

    function contentTextCounter(event) { 

        var element = document.getElementById("myContenido");
        if (contenido.length > 5000){        
            element.style.backgroundColor = "red";		
        }
        else{  
            element.style.backgroundColor = "var(--color4)";
        }
    }

    $: switch(categoria){
        case(-1):{
           
            break;
        }
        case(1):{
            placeholderTexto = "Categoría Historias Personales: Está dedicada a narrar historias que nos ocurrieron o conocimos. NO CONTENIDO ERÓTICO O ROMÁNTICO."
            break;
        }
        case(5):{
            placeholderTexto = "Categoría Consejos: Está dedicada a dar o recibir opiniones acerca de nuestra conducta. NO CONTENIDO ERÓTICO."
            break;
        }
        case(6):{
            placeholderTexto = "Categoría Historia: Está dedicada a temas relacionados con la historia de la humanidad, acontecimientos históricos. NO HISTORIAS PERSONALES, NO CONTENIDO ERÓTICO."
            break;
        }
        case(8):{
            placeholderTexto = "Categoría Hágalo Usted Mismo: Está dedicada a la fabricación o reparación de objetos por uno mismo. NO CONTENIDO ERÓTICO."
            break;
        }
        case(18):{
            placeholderTexto = "Categoría Físico: Está dedicada a temas relacionados con partes corporales, complejos físicos (Altura, etc ). NO CONTENIDO ERÓTICO."
            break;
        }
        case(26):{
            placeholderTexto = "Categoría Sexy: Está dedicada a contenido erótico, romántico, etc. NO HOMO."
            break;
        }
        case(37):{
            placeholderTexto = "Categoría Salud: Está dedicada a contenido relacionado con dolencias y bienestar tanto físico como mental. NO CONTENIDO ERÓTICO."
            break;
        }
        case(41):{
            placeholderTexto = "Categoría Fitness: Está dedicada a contenido relacionado con la práctica de actividades físicas y desarrollo de una vida sana. NO CONTENIDO ERÓTICO."
            break;
        }
        case(22):{
            placeholderTexto = "Categoría Ufftopia: Está dedicada a temas relacionados ÚNICAMENTE con la página (diseño, moderacion, etc). NO ES UNA CATEGORÍA GENERAL, NO HISTORIAS PERSONALES, NO CONTENIDO ERÓTICO."
            break;
        }
        case(43):{
            placeholderTexto = "Categoría Inteligencia Artificial: Está dedicada a la discusión y exploración de la inteligencia artificial en diversas aplicaciones y campos, desde la visión por computadora hasta el procesamiento del lenguaje natural. NO HENTAI, NO PORNO."
            break;
        }
        case(33):{
            placeholderTexto = "Categoría Lugares: Está dedicada a compartir y descubrir diversos lugares alrededor del mundo. Desde viajes y turismo hasta exploraciones locales, aquí puedes explorar y discutir destinos, recomendaciones y experiencias en diferentes ubicaciones geográficas."
            break;
        }
        default: {
            placeholderTexto ="Respetá las categorias para respetar a los demás anons."
        }
    }
    
    let filtro = "";

    function cancelar(){
       mostrar = false
       filtro = "";    
       placeholderTexto = " Escribí un texto..."       
   }
  



function seleccionarPrimeraCoincidente() {

    const todasLasOpciones = [
      ...categoriaAyuda,
      ...categoriaCultura,
      ...categoriaEntretenimiento,
      ...categoriaErotico,
      ...categoriaHistorias,
      ...categoriaInternet,
      ...categoriaPolitica,
      ...categoriaTecnologia,
      ...categoriaUfftopia,
      ...categoriaVehiculo,
      ...categoriaXXX,
      ...categoriaNaturaleza
    ];

    const filtroLimpio = filtro.trim();

    if (filtroLimpio === "") {
        categoria = -1; // Establecer a -1 cuando el filtro está vacío
        placeholderTexto = " Escribí un texto..."
        return;
    }
    const primeraCoincidente = todasLasOpciones.find(
      (c) =>
        c.nombre.toLowerCase().startsWith(filtroLimpio.toLowerCase()) 
    );

    if (primeraCoincidente) {
      categoria = primeraCoincidente.id;     
    }
    else {
      categoria = -1; // Establecer a -1 cuando no hay coincidencias
      placeholderTexto = " Escribí un texto..."
    }
  }
  

function seleccionarOpcion(id) {
  categoria = id;
  filtro = "";
}
    </script>

    

    {#if mostrar}
    <div  transition:fly={{duration:200}}  class="sombra" style="position:fixed;left:0;top:0">
        <form  
            id="crear-hilo-form" 
            class="formulario crear-hilo panel"
            on:submit|preventDefault
        >
            <!-- svelte-ignore a11y-autofocus -->
            <input id="myTitulo" autofocus on:keyup ={titleTextCounter} on:KeyDown={titleTextCounter} bind:value={titulo} name="titulo" style="background: var(--color4);" placeholder=" Titulo">
            <input type="text" bind:value={filtro} placeholder="Buscar categoría..." on:input={() => seleccionarPrimeraCoincidente()} />
            <select style="background: var(--color4);" bind:value={categoria} name="categoria">
                <option value="-1" selected="selected"  disabled="disabled">Categoría</option>
                <optgroup label="Actualidad" class="subcategorias">
                  {#each categoriaPolitica as c}
                    {#if c.nsfw == false}
                      <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                    {:else}
                      <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                    {/if}
                  {/each}
                </optgroup>
                <optgroup label="Ayuda" class="subcategorias">
                    {#each categoriaAyuda as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup> 
                <optgroup label="Conocimiento" class="subcategorias">
                    {#each categoriaTecnologia as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>           
                <optgroup label="Cultura" class="subcategorias">
                    {#each categoriaCultura as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>       
                <optgroup label="Entretenimiento" class="subcategorias">
                    {#each categoriaEntretenimiento as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>     
                <optgroup label="Erótico" class="subcategorias">
                    {#each categoriaErotico as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>       
                <optgroup label="Historias" class="subcategorias">
                    {#each categoriaHistorias as c}                
                        {#if c.n == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>     
                <optgroup label="Internet" class="subcategorias">
                    {#each categoriaInternet as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>  
                <optgroup label="Naturaleza" class="subcategorias">
                    {#each categoriaNaturaleza as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>         
                <optgroup label="Ufftopia" class="subcategorias">
                    {#each categoriaUfftopia as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>      
                <optgroup label="Transportes" class="subcategorias">
                    {#each categoriaVehiculo as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>        
                <optgroup label="XXX" class="subcategorias">
                    {#each categoriaXXX as c}                
                        {#if c.nsfw == false}
                        <option value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {:else}
                        <option style="background: var(--color6);" value="{c.id}" on:click={() => seleccionarOpcion(c.id)}>{c.nombre}</option>
                        {/if}  
                    {/each}
                </optgroup>      
              </select>
                {#if !escribiendoRedactazo}
                    <textarea 
                    class="custom-input"
                    rows="15" 
                    style="background: var(--color4);margin-bottom: -1%;" 
                    bind:value={contenido} name="contenido" 
                    on:focus={onTeaxtAreaFocus} id="myContenido"  on:keyup ={contentTextCounter} on:KeyDown={contentTextCounter}
                    placeholder={placeholderTexto}></textarea>
                {/if}
                {#if escribiendoRedactazo}
                    <div class="">
                            <textarea 
                                class="custom-input"
                                rows="15" 
                                style="background: var(--color4);" 
                                bind:this={textarea2}
                                bind:value={contenido} name="contenido" 
                               
                                on:focus={onTeaxtAreaFocus} id="myContenido"  on:keyup ={contentTextCounter} on:KeyDown={contentTextCounter}
                                on:blur={() => escribiendoRedactazo = false}
                                placeholder={placeholderTexto}></textarea>                                
                    </div>
                {/if}
                
                <input  bind:value={hashtags} name="hashtags" style="background: var(--color4);margin-top: 15px;" placeholder="Hashtags separados por comas">
              
                <div class="container"> 
                    <div style="width: 90%;"><MediaInput bind:media={media} commands={true} compacto={true}></MediaInput></div><div style="width:10%;"><FormularioEncuesta bind:opciones={encuesta}/></div> 
                </div> 
                
            <ErrorValidacion {error}/>
    
            {#if $globalStore.usuario.esMod}
                <div style=" flex-direction:row; display:flex">
                    <!--Checkbox bind:checked={mostrarRango} right>Tag_Mod</Checkbox-->
                    <Checkbox bind:checked={mostrarNombre} right>Nombre</Checkbox>
                </div>
            {/if}
            <Captcha visible={config.general.captchaHilo}  bind:token={captcha}/>
            <div style="display:flex;     justify-content: flex-end;">                             
                    <button class="iniciar mousechange" type='submit' color="primary" disabled={cargando} on:click={crear}>
                        <Spinner {cargando}>Iniciar topic</Spinner>
                    </button>              
            </div>
            <div style="display:flex; justify-content:center;">                             
                <Button style="width: 100%;" color="var(--color5)" on:click={() => cancelar()}>Cancelar</Button>  
            </div>
          
        </form>
    
    </div>
    {/if}
    
    <style>
    .custom-input::placeholder {
    color: #999; /* Color de texto deseado para el placeholder */
    transition: color 0.3s ease; /* Transición de color al cambiar */
    }

    /* Estilo cuando el input con la clase 'custom-input' está enfocado */
    .custom-input:focus::placeholder {
        color: #ff5722; /* Nuevo color del placeholder al enfocar */
    }

        .nav-boton {
            padding: 12px;
            align-items: center;
            display:flex;
            cursor: pointer;
        }    
        video {
            width: 486px;
            height: 319px;
            top: -10px;
            position: absolute;
        }
        form {
            border-top: 2px solid var(--primary);
            max-height: 80vh !important;
            overflow-y: auto;
            max-width: unset;
            width: fit-content;
            position: relative;
            width: 600px;
        }
        @media (min-width: 600px) {
        form {
                min-width: 600px;
            }
        }
        @media (max-width: 400px) {
        form {
                width: 100%;
                height: 94vh;
            }
        }
    
        @media (max-width: 600px) {
            form {
                width: 100vw !important;
                height: calc(100vh - 50px) !important;
                max-height: calc(100vh - 50px) !important;
            }
        }
        .tag-container {
  display: flex;
  flex-flow: row wrap;
}

.tag{
  pointer-events: none;
  background-color: #242424;
  color: white;
  padding: 6px;
  margin: 5px;
}

.tag::before {
  pointer-events: all;
  display: inline-block;
  content: 'x';  
  height: 20px;
  width: 20px;
  margin-right: 6px;
  text-align: center;
  color: #ccc;
  background-color: #111;
  cursor: pointer;
}

    
        .expandida {
            position: absolute;
            top: 10px;
            top: 10px;
            bottom: 50px;
            z-index: 1;
            width: calc(100% - 20px);
            height: calc(100% - 70px);
        }   
        .container > div {    
            display: inline-block;           
            width: 50%; 
        } 

        .iniciar{
            border-radius: 15px;
            border-color:var(--color6);
            color:var(--color5);
            background-color:transparent;
             font-size: larger;
             font-weight: 900;
             width: -webkit-fill-available;
             height: 40px;
             width: 100%;
             margin-bottom: 5px;
        }
        .mousechange:hover {
        cursor: pointer;
        }
    </style>