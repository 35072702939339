<script>
    import {Textfield, Button, Ripple, Checkbox} from "svelte-mui"
    import RChanClient from "../../RChanClient";
    import Captcha from "../Captcha.svelte";
    import ErrorValidacion from "../ErrorValidacion.svelte";
    import config from "../../config"
    import { writable } from 'svelte/store';
    import Estadisticas from '../Estadisticas.svelte';

    let token = window.model.token  

  let qr;
    const initializeQR = () => {       
      qr = window.QRCode;        
      const rcode = document.getElementById("qrcode");
      const textInput = document.getElementById("myInput");
      const code = new QRCode(rcode);
      code.makeCode(textInput.value.trim());     
	}
    
function myFunction() {

    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copiado";
}

function outFunc() {
  var tooltip = document.getElementById("myTooltip");
  tooltip.innerHTML = "Copiar al portapapeles";
}

</script>
<svelte:head>	
  <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js" on:load={initializeQR}></script>
</svelte:head>
<div class="fondo"></div>

<main> 
   
    <section style="word-break: break-all;">
        <h2>Este es tu token de sesión</h2>      
        <input type="text" value={token} readonly id="myInput">
        <div id="qrcode"></div>
        <div class="tooltip">
            <button class="mousechange" on:click={myFunction}  on:mouseout={outFunc} type="submit" style="border-radius:15px;border-color:var(--color6); color: var(--color5); background-color: transparent; font-size: larger; font-weight: 900; width: -webkit-fill-available; height: 40px;width: 100%;">
                <span class="tooltiptext" id="myTooltip">Copiar Token</span>
                Copiar Token                
            </button> 
        </div>       
        <h4>Sirve para poder usar la misma sesión en diferentes navegadores y dispositivos</h4>
        <p>Para utilizarlo tenes que dirigirte al <a href="/Login">login</a> y cliquear la opción usar token</p>
    </section>
</main>

<style>
    main {
        margin:auto;
        height: auto;
        padding: 16px;
        max-width: 1600px;
        margin-top: 80px;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    section {
        max-width: 100vh;
        display: flex;
        flex-direction: column;
        gap: 16px;
        background: var(--color2);
        padding: 16px ;
        border-radius: 4px;
        border-top: solid 2px var(--primary);
    }
    form {
        color: white !important;
        /* background: var(--color2); */
    }

    video {
        position: fixed;
        z-index: -1;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: calc(100vh - 400px);
        width: auto;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0.4;
        /* filter: contrast(1.5) brightness(1.5); */
    }

    .fondo{
        position: fixed;
        top:0;
        left:0;
        width: 100vw;
        height: 100vh;
        z-index: -100;      
        background-size: cover !important;
    }
    :global(.label) {
        color: #ffffffcc !important
    }

    .tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.mousechange:hover {
        cursor: pointer;
    }
</style>