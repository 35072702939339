<script>
    import Dialogo from '../Dialogo.svelte'
    import globalStore from '../../globalStore'
    import RChanClient from '../../RChanClient';
    import {MotivoDenuncia} from '../../enums'

    export let comentarioId = ""
    export let hiloId = ""

    let motivo = -1
    let aclaracion = ""

    $: tipo = (comentarioId == "" || !comentarioId)? 0:1
    $:tipoString = tipo == 0? "topic" : "comentario"

    $: onChange(visible);
    
    function onChange(visible) {
      if(!visible){
         motivo = -1
         aclaracion = ""
      }
    }
   
    export let  visible = false    

    motivo = (visible)? motivo:-1
    aclaracion = (visible)? aclaracion:""


</script>

<Dialogo    
    bind:visible={visible} 
    textoActivador="Reportar {tipoString}" 
    titulo="Reportar {tipoString}"
    accion={() =>    RChanClient.Denunciar(tipo, hiloId, motivo, aclaracion, comentarioId)}>
    <slot slot="activador">        
    </slot>
   
    <div slot="body"> 
            
        <p>Reportar el {tipoString} {tipoString == "topic"? hiloId : comentarioId} </p>
        <select bind:value={motivo}  id="motivo" name="motivo"> 
            <option value="-1" selected="selected" disabled="disabled">*Motivo</option>           
            {#each Object.keys(MotivoDenuncia) as k, i}
                <option value={MotivoDenuncia[k]}>{k}</option>
            {/each}
        </select>

        <textarea placeholder="*Aclaración" bind:value={aclaracion}></textarea>
        
    </div>
</Dialogo>
