<script>
    import { Ripple, Sidepanel, Checkbox } from 'svelte-mui';
    import {fly} from 'svelte/transition'
    import config from '../config';
    import globalStore from '../globalStore'
    import RChanClient from '../RChanClient';
    import Dialogo from './Dialogo.svelte';
    import Ajustes from './Dialogos/Ajustes.svelte';
    import {abrir} from './Dialogos/Dialogos.svelte';
    import Signal  from "../signal";

    $:usuario = $globalStore.usuario

    export let mostrar = true

    let mostrarCategorias = false
    let mostrarAjustes = false

    let mostrarAyuda = false
    let mostrarCultura = false
    let mostrarEntretenimiento = false
    let mostrarErotico = false
    let mostrarHistorias = false
    let mostrarInternet = false
    let mostrarPolitica = false
    let mostrarTecnologia = false
    let mostrarUfftopia = false
    let mostrarVehiculo = false
    let mostrarXXX = false
    let mostrarNaturaleza = false    

    let mostrarJuegos = false

    $: if (mostrarAjustes) mostrar = false
   
    let categorias =  config.categorias.map(c => {
        c.activa = $globalStore.categoriasActivas.includes(c.id)
        c = c
        return c
    })

    let categoriaAyuda =  categorias.filter(c => c.groupId == 1)
    let categoriaCultura =  categorias.filter(c => c.groupId == 2)
    let categoriaEntretenimiento =  categorias.filter(c => c.groupId == 3)
    let categoriaErotico =  categorias.filter(c => c.groupId == 9)
    let categoriaHistorias =  categorias.filter(c => c.groupId == 6)
    let categoriaInternet =  categorias.filter(c => c.groupId == 8)
    let categoriaPolitica =  categorias.filter(c => c.groupId == 5)
    let categoriaTecnologia =  categorias.filter(c => c.groupId == 4)
    let categoriaUfftopia =  categorias.filter(c => c.groupId == 7)
    let categoriaVehiculo =  categorias.filter(c => c.groupId == 10)
    let categoriaXXX =  categorias.filter(c => c.groupId == 11)
    let categoriaNaturaleza =  categorias.filter(c => c.groupId == 12)
    
    $: $globalStore.categoriasActivas = categorias.filter(c => c.activa).map(c => c.id)

    function update(id,active){        
        categorias.filter(c => c.id == id)[0].activa = active;
        $globalStore.categoriasActivas = categorias.filter(c => c.activa).map(c => c.id)
    }
    
    function mostrarMensajeGlobal(){
        mostrar = false;
        abrir.global();
    }
    async function desloguearse() {
        await RChanClient.deslogearse()
        location.reload()
    }

 let computadorasConectadas = window.estadisticas.computadorasConectadas;
 let registroAbierto = window.estadisticas.registroAbierto;
 let horarioMadrugada = window.estadisticas.horarioMadrugada;
 Signal.coneccion.on('estadisticasActualizadas', estadisticas => {
      computadorasConectadas = estadisticas.computadorasConectadas;  
      registroAbierto = estadisticas.registroAbierto;
      horarioMadrugada = estadisticas.horarioMadrugada;   
  })
    
</script>


<Sidepanel left bind:visible={mostrar} style="background: red">
    <section class="menu-principal">
        <div class="menu-principal-header">
            <a href="/">
                <h1 class="pagina" style="font-family: 'terminator';">Ufftopia 2.6.0</h1>
            </a>
            {#if usuario.estaAutenticado}
            <div class="conectados">Dispositivos conectados: {computadorasConectadas}</div>
            {#if registroAbierto}
            <div class="conectados">Registro de usuarios: Abierto</div>
            {:else}  
             <div class="conectados">Registro de usuarios: Cerrado</div>
            {/if} 
            {#if horarioMadrugada}
            <div class="conectados">Horario: Madrugada</div>
            {:else}  
             <div class="conectados">Horario: Normal</div>
            {/if}            
          
            <span class="mod" style="display: block;text-align: center;">Hola {usuario.userName}!</span>
            {/if}
            
        </div>
        <ul>
            {#if !usuario.estaAutenticado}
            <a href="/Login">
                <li> <icon class="fe fe-log-in"/> Iniciar Sesion  <Ripple/></li>
            </a>
            <a href="/Inicio">
                <li> <icon class="fe fe-user"/> Crear Sesion  <Ripple/></li>
            </a>
            {/if}
            {#if $globalStore.usuario.estaAutenticado}
            <li on:click={() => mostrarCategorias = !mostrarCategorias}>
                <icon class="fe fe-menu"/> Categorias 
                <span style="margin-left:auto"></span>

                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarCategorias?180:0}deg);transition: all 0.2s ease 0s;"/>  

                <Ripple/></li>
            {#if mostrarCategorias}
                 <!------Politica----->
                 <li on:click={() => mostrarPolitica = !mostrarPolitica}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarPolitica?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Actualidad
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarPolitica}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaPolitica as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}                 

                <!------AYUDA----->
                <li on:click={() => mostrarAyuda = !mostrarAyuda}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarAyuda?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Ayuda 
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarAyuda}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaAyuda as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}

                <!------Conocimiento----->
                <li on:click={() => mostrarTecnologia = !mostrarTecnologia}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarTecnologia?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Conocimiento
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarTecnologia}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaTecnologia as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}

                <!------Cultura----->
                <li on:click={() => mostrarCultura = !mostrarCultura}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarCultura?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Cultura 
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarCultura}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaCultura as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}

                 <!------Entretenimiento----->
                 <li on:click={() => mostrarEntretenimiento = !mostrarEntretenimiento}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarEntretenimiento?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Entretenimiento 
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarEntretenimiento}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaEntretenimiento as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}

                 <!------Erotico----->
                 <li on:click={() => mostrarErotico = !mostrarErotico}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarErotico?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Erótico 
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarErotico}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaErotico as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}

                <!------Historias----->
                <li on:click={() => mostrarHistorias = !mostrarHistorias}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarHistorias?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Historias 
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarHistorias}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaHistorias as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}

                <!------Internet----->
                <li on:click={() => mostrarInternet = !mostrarInternet}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarInternet?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Internet 
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarInternet}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaInternet as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}

                <!------Naturaleza----->
                <li on:click={() => mostrarNaturaleza = !mostrarNaturaleza}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarNaturaleza?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Naturaleza 
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarNaturaleza}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaNaturaleza as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}
                
                <!------Ufftopia----->
                <li on:click={() => mostrarUfftopia = !mostrarUfftopia}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarUfftopia?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Ufftopia
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarUfftopia}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaUfftopia as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}

                <!------Vehiculo----->
                <li on:click={() => mostrarVehiculo = !mostrarVehiculo}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarVehiculo?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    Transporte
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarVehiculo}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaVehiculo as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}

                <!------XXX----->
                <li on:click={() => mostrarXXX = !mostrarXXX}>
                    <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarXXX?180:0}deg);transition: all 0.2s ease 0s;"/>  
                    <span style="margin-left: 26%;"></span> 
                    XXX
                 <Ripple/>                 
                <span style="margin-left:auto"></span>   
                </li>
                {#if mostrarXXX}
                    <div transition:fly={{y: -50, duration:150}}>                    
                            {#each categoriaXXX as c (c.id)}                   
                                <li class="categoria-link">
                                    <a href="/{c.nombreCorto}">
                                        <icon class="fe fe-circle"/>  {c.nombre}
                                    </a>
                                    <span style="width: fit-content;margin-left: auto;">
                                    <Checkbox onclick="{update(c.id,c.activa)}" bind:checked={c.activa} right></Checkbox></span> 
                                    <Ripple/>
                                </li>    
                            {/each}

                    </div>
                {/if}               
            {/if}
            {/if}
            <hr>
            {#if $globalStore.usuario.estaAutenticado}
                <a href="/Comentarios">
                    <li> <icon class="fe fe-send"/> Mis Comentarios  <Ripple/></li>
                </a>
                <a href="/Creados">
                    <li> <icon class="fe fe-edit"/> Mis Topics <Ripple/></li>
                </a>
                <a href="/Favoritos">
                    <li> <icon class="fe fe-star"/> Favoritos  <Ripple/></li>
                </a>
                <a href="/Seguidos">
                    <li> <icon class="fe fe-eye"/> Seguidos  <Ripple/></li>
                </a>
                <a href="/Ocultos">
                    <li> <icon class="fe fe-eye-off"/> Ocultos  <Ripple/></li>
                </a>
                <a href="/Sugeridos">                 
                  <li> <icon class="fe fe-sun"/><h4>Sugeridos</h4> <Ripple/></li>                
                </a>
                <hr>               
                   <li on:click={() => mostrarMensajeGlobal()}><icon class="fe fe-mail"/> Mensaje Global <Ripple/></li>
                <hr>
                <li >
                    <a href="https://ufftopia.net/player.html" target=”_blank”>  
                        <li>
                            <icon style ="padding-left: 0;" class="fe fe-music"/> Radio  <Ripple/>   
                        </li>
                     </a>
                </li>
                <hr>
                <li >
                    <a href="https://ufftopia.net/Entries/index" target=”_blank”>  
                        <li>
                            <icon style ="padding-left: 0;" class="fe fe-globe"/> Ufftopedia  <Ripple/>   
                        </li>
                     </a>
                </li>
                 <hr>
                 <hr>
                 <li >
                     <a href="https://ufftopia.net/jslinux/index.html" target=”_blank”>  
                         <li>
                             <icon style ="padding-left: 0;" class="fe fe-cpu"/> JsLinux  <Ripple/>   
                         </li>
                      </a>
                 </li>
                  <hr>
                
                <li on:click={() => mostrarJuegos = !mostrarJuegos}>
                    <icon class="fe fe-play"/> Juegos 
                    <span style="margin-left:auto"></span>
    
                        <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
    
                    <Ripple/></li>
                {#if mostrarJuegos}
              
                     <!------Juegos----->                     
                     <a href="/arkanoid/public/index.html" target=”_blank”>  
                        <li>
                            <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                            <span style="margin-left: 26%;"></span> 
                            Arkanoid (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                        </li>
                     </a>
                     <a href="/BattleCity/BattleCity.html" target=”_blank”>  
                        <li>
                            <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                            <span style="margin-left: 26%;"></span> 
                            Battle City (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                        </li>
                     </a>
                     <a href="/cyberpunk/docs/index.html" target=”_blank”>  
                        <li>
                            <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                            <span style="margin-left: 26%;"></span> 
                             Cyberpunk (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                        </li>
                    </a>
                     <a href="/game.html" target=”_blank”>  
                            <li>
                                <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                                <span style="margin-left: 26%;"></span> 
                                Cubo (pc)
                                <Ripple/>                 
                                <span style="margin-left:auto"></span>   
                            </li>
                     </a>
                     <a href="/mario/index.html" target=”_blank”>  
                            <li>
                                <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                                <span style="margin-left: 26%;"></span> 
                                Mario (pc)
                                <Ripple/>                 
                                <span style="margin-left:auto"></span>   
                            </li>
                    </a>
                    <a href="/emulatrix/index.html" target=”_blank”>  
                        <li>
                            <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                            <span style="margin-left: 26%;"></span> 
                             Multiplataforma (pc/mobile)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                        </li>
                    </a>
                    <a href="/n64/dist/index.html" target=”_blank”>  
                        <li>
                            <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                            <span style="margin-left: 26%;"></span> 
                             Nintendo 64 (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                        </li>
                    </a>
                    <a href="/enge-js-main/index.html" target=”_blank”>  
                        <li>
                            <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                            <span style="margin-left: 26%;"></span> 
                             Playstation I (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                        </li>
                    </a>
                    <a href="/outrun/index.html" target=”_blank”>  
                            <li>
                                <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                                <span style="margin-left: 26%;"></span> 
                                OutRun (pc/mobile)
                                <Ripple/>                 
                                <span style="margin-left:auto"></span>   
                            </li>
                    </a>

                    <a href="/pacman.html" target=”_blank”>  
                            <li>
                                <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                                <span style="margin-left: 26%;"></span> 
                                Pacman (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                            </li>
                    </a>

                    <a href="/pinball/PinballGame.htm" target=”_blank”>
                            <li>
                                <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarPolitica?180:0}deg);transition: all 0.2s ease 0s;"/>  
                                <span style="margin-left: 26%;"></span> 
                                Pinball (pc/mobile)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                            </li>
                    </a>
                    <a href="/Transplant/game/index.html" target=”_blank”>  
                        <li>
                            <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                            <span style="margin-left: 26%;"></span> 
                             Transplant (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                        </li>
                    </a>
                    <a href="/tetris/index.html" target=”_blank”>  
                        <li>
                            <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarJuegos?180:0}deg);transition: all 0.2s ease 0s;"/>  
                            <span style="margin-left: 26%;"></span> 
                             Tetris (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                        </li>
                    </a>
                    <a href="/WildGunman/index.html" target=”_blank”>
                            <li>
                                <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarPolitica?180:0}deg);transition: all 0.2s ease 0s;"/>  
                                <span style="margin-left: 26%;"></span> 
                                Wild Gunman (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                            </li>
                    </a>
                    <a href="/wolf3d-master/wolf.html" target=”_blank”>
                            <li>
                                <icon class="fe fe-chevron-down" style="padding:0;transform: rotate({mostrarPolitica?180:0}deg);transition: all 0.2s ease 0s;"/>  
                                <span style="margin-left: 26%;"></span> 
                                Wolfenstein (pc)
                            <Ripple/>                 
                            <span style="margin-left:auto"></span>   
                            </li>
                    </a>
                    

                   {/if}
             
                <hr>
            {/if}
            {#if $globalStore.usuario.estaAutenticado}
                  <li on:click={() => mostrarAjustes = true}> <icon class="fe fe-settings"/> Ajustes  <Ripple/></li>
            {/if}
            <a href="/reglas.html">
                <li > <icon class="fe fe-align-justify"/>Términos de uso y Reglas  <Ripple/></li>
            </a>
                       
            <a href="/changelog.html">
                <li > <icon class="fe fe-list"/>Changelog<Ripple/></li>
            </a>           
          
            <hr>
            {#if $globalStore.usuario.tieneToken}
                <a href="/comandos.html">                    
                    <li> <icon class="fe fe-terminal"/>Comandos Sentinel<Ripple/></li>
                </a>
            {/if}            
            {#if $globalStore.usuario.tieneToken}
                <a href="/Token">
                    <li> <icon class="fe fe-user-check"/> Ver token <Ripple/></li>
                </a>
            {/if}
            {#if $globalStore.usuario.estaAutenticado}
                <li on:click={desloguearse}> <icon class="fe fe-log-out"/> Salir  <Ripple/></li>
            {/if}
            <hr>


            
            {#if $globalStore.usuario.esMod}
            <a href="/Moderacion">
                <li> <icon class="fe fe-triangle"/> Moderacion  <Ripple/></li>
            </a>
            {/if}
            {#if $globalStore.usuario.esAdmin}
            <a href="/Administracion">
                <li> <icon class="fe fe-triangle"/> Administracion  <Ripple/></li>
            </a>
            {/if}            
            <hr>
        </ul>
    </section>
</Sidepanel>
<Ajustes bind:visible={mostrarAjustes}></Ajustes>

<style>
    :global(.side-panel) {
        width: auto !important;
        min-width: 256px;
    }
    .categoria-link a {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .canales {
        background-color: crimson;
        width: 5%;
        border-style: groove;
        border-color: cyan;
        border-radius: 5px;      
        text-align: -webkit-center;
    }
    .mod{
        color: white;
        text-transform: uppercase;
        font-family: terminator;
        font-weight: bold;
        background: var(--color4);
        margin-left: auto;
        padding: 6px;
        border-radius: 4px;
        border-top: 4px var(--color5) solid;
    }

    .pagina{
        font-family: 'terminator';
        margin-bottom: 10%;
    }
    .conectados{
        margin-left: 12%;
        margin-bottom: 10%;
        font-size: revert;
    }

    h4 {
    color: var(--color5);
    background-image: -webkit-linear-gradient(92deg,violet,red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: hue 60s infinite linear;
}

@-webkit-keyframes hue {
    from {
      -webkit-filter: hue-rotate(0deg);
    }
    
    to {
      -webkit-filter: hue-rotate(360deg);
    }
}
</style>