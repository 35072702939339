<script>
    import MediaType from "../MediaType";
    import {onMount} from "svelte/";
    import {Icon, Button, Ripple} from "svelte-mui"
    import RChanClient from '../RChanClient';

    export let media
    export let modoCuadrado = false
    
    let base = window.location.hostname; 

    $: vistaPrevia = modoCuadrado? media.vistaPreviaCuadrado : media.vistaPrevia

    export let abierto = false 
    let oculto = false
    let vid
    if( media.tipo  == MediaType.Pdf || media.tipo  == 9 || media.tipo == MediaType.Tv){
        abierto = true;
    }

    function abrirVideo() {
        abierto = true
        console.log(vid);
        setTimeout(async () => {
            vid.play()
        }, 1)
    }     
    
    async function change(number){
        try{
        let res = await RChanClient.getChannel(number);      
         media.url = res.data;
       
        } catch (error) {
            console.log( error);
        }
        
    }
</script>
{#if media.tipo  == 11}
<div id="mediaDiv" class="mediaJdoodle" 
    class:abierto
    class:modoCuadrado
    class:youtube={media.tipo == MediaType.Youtube}>   
    {#if oculto}
        <div style="height:64px;"></div>
    {:else}
    <iframe title="Jdoodle" src="{media.url}" style="top: 0; left: 0; width: 100%; height: 450px; border: 0;"  allow="encrypted-media;"></iframe>
    {/if}
 </div>
{:else}

<div id="mediaDiv" class="media" 
    class:abierto
    class:modoCuadrado
    class:youtube={media.tipo == MediaType.Youtube}>
    {#if !abierto}
        <div class="ocultar">
            <Button on:click={() => oculto = !oculto} class="cerrar" icon>
                <i class="fe fe-eye{!oculto?'-off':''}"></i> 
            </Button>
        </div>
    {/if}
    {#if oculto}
        <div style="height:64px;"></div>
    {:else if media.tipo  == MediaType.Imagen}
        {#if media.esGif}
            <a href="/Media/{media.url}" target="_blank">
                <img src="/Media/{media.url}" alt="" srcset="">
            </a>
        {:else}
            <a href="/Media/{media.url}" target="_blank">
                <img src="{vistaPrevia}" alt="" srcset="">
            </a>
        {/if}
        {:else if media.tipo == MediaType.Video}        
            {#if abierto}
                <video playsinline muted bind:this={vid} loop controls  src="/Media/{media.url}" style="margin-bottom: 16px;"></video>
                <Button on:click={() => abierto = false} class="cerrar" icon>
                    <i class="fe fe-x"></i> 
                </Button>
                {:else}
                <img on:click={abrirVideo} src="{vistaPrevia}" alt="" srcset="">
                <Button on:click={abrirVideo}  color="red" class="play" icon>
                    <i class="fe fe-play" style="position: relative;left: 2px;"></i> 
                </Button>
            {/if}
        {:else if media.tipo == MediaType.Youtube}        
                <div style="display: none;">{abierto = true} </div> 
                <div class="youtube-container">
                    <iframe title="youtube" allowfullscreen src="https://www.youtube.com/embed/{media.id}?autoplay=0" referrerpolicy='no-referrer-when-downgrade'> </iframe>
                </div>
               
    {:else if media.tipo == MediaType.Tv}          
          
    <div class="youtube-container">
        <iframe title="Tv"  allow="encrypted-media"  allowfullscreen src={media.url} id="iframe" name="iframe" frameborder="0" scrolling="no" ></iframe>        
    </div>                       
    
    {:else if media.tipo  == MediaType.Pdf}                    
         <iframe title="pdf" src="https://drive.google.com/viewerng/viewer?url={base}/Media/{media.url}? pid=explorer&efh=false&a=v&chrome=false&embedded=true" style="height: 250px;" frameborder="0" />
         <a href="https://{base}/Media/{media.url}" target="blank">Abrir PDF</a>
    {:else if media.tipo  == 6}   
        <div style="display: none;">{abierto = true} </div> 
        <div class="youtube-container">
            <iframe title="preview" src="{media.url}" style="height: 100%;"></iframe>
       </div>   
    {:else if media.tipo  == 7}   
        <div style="display: none;">{abierto = true} </div> 
        <div class="youtube-container">
            <iframe title="preview" src="{media.url}" ></iframe>
        </div>    
    {:else if media.tipo  == 8}   
        <div style="display: none;">{abierto = true} </div>  
        <div class="youtube-container">
            <iframe title="preview" src="{media.url}" ></iframe>
        </div>
   
    {:else if media.tipo  == 9}       
      <iframe title="spotify" src="{media.url}" style="height: 250px;" frameBorder="0" allowtransparency="true" allow="encrypted-media"/>    
    {:else if media.tipo  == 10}
        <div style="display: none;">{abierto = true} </div>            
        <div style="max-width: 56vh;"><div style="left: 0; width: 100%; height: 0; 
            padding-bottom: 177.7778%; "><iframe title="tiktok" src="{media.url}" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen="" scrolling="no" allow="encrypted-media;"></iframe></div></div>    
        {:else if media.tipo  == 12}  
        <div style="display: none;">{abierto = true} </div> 
            <div class="youtube-container">
                <iframe allowfullscreen title="preview" src="{media.url}" ></iframe>
        </div>
       
    {:else if media.tipo  == 13}      
       <div style="display: none;">{abierto = true} </div> 
        <div class="youtube-container">
            <iframe title="okrusia" src="{media.url}" style="height: 250px;" frameBorder="0" allowtransparency="true" allow="encrypted-media"/> 
        </div>         
    {/if}
    
</div>
{/if}
{#if media.tipo == MediaType.Tv}   
<div>
  
</div>
<div class="block block-channel" style="padding-top: 45px;">
    <button type="button"  on:click={() =>change(3)} data-item="RcUjwuQKEFg" class="btn channel cnl-namber">3</button>
    <button type="button"  on:click={() =>change(2)} data-item="9S5XTN6vgDg" class="btn channel cnl-namber">2</button>
    <button type="button"  on:click={() =>change(1)} data-item="MsVriDLmDyI" class="btn channel cnl-namber">1</button>
    <button type="button"  on:click={() =>change(6)} data-item="FJ2aNEwlC7I" class="btn channel cnl-namber">6</button>
    <button type="button"  on:click={() =>change(5)} data-item="O-Vb08vyvpE" class="btn channel cnl-namber">5</button>
    <button type="button"  on:click={() =>change(4)} data-item="slO8lwP6nuQ" class="btn channel cnl-namber ">4</button>
    <button type="button"  on:click={() =>change(9)} data-item="wq4gti-zVes" class="btn channel cnl-namber">9</button>
    <button type="button"  on:click={() =>change(8)} data-item="C8QxjB8mV4o" class="btn channel cnl-namber">8</button>
    <button type="button"  on:click={() =>change(7)} data-item="CWF64XhiXGg" class="btn channel cnl-namber">7</button>  
  </div>
{/if}   



<style>
    video, img {
        border-radius: 4px;
        max-height: 80vh;
    }
    .media {
        position: relative;
        width: 50%;
        max-height: 80vh;
        display: flex;
        flex-direction: column;
    }
    .mediatv {       
        width: 100%;
        max-height: 80vh;       
        flex-direction: column;
    }
    .mediaJdoodle{
        position: relative;
        width: 100%;        
        display: flex;        
    }
    video, .abierto {
        width:100%;
        max-width:100%;
    }

    .media :global(button) {
        position: absolute;
        top: 4px;
        right: 4px;
    }
    .media :global(.play) {
        position: absolute;
        top:50%;
        transform: translateX(50%) translateY(-50%) scale(2);
        right: 50%;
    }

    .youtube-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
    }

    .tv-container {       
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
    }

    .youtube-container iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: none;
        border-radius: 4px;
    }

    .medialink{
        background: var(--color1);
        width: 100%;
        text-align: center;
        padding: 2px 0;
        border-radius: 0 0 4px 4px;
        border: solid 1px black;
        border-top: none;
        font-size: 12px;
        color: #ffffffe3 !important;
    }
    a {
        width: 100%;
    }

    .media .ocultar {
        display: none;
    }
    .media:hover .ocultar {
        display: block  ;
    }
    .youtube, iframe, .youtube img {
        border-radius: 4px 4px 0 0 !important;
    }
    .youtube iframe {
        border-radius: 0 !important;
    }

    .modoCuadrado {
        width: 100%;
        max-width: 100%;
    }

    .pdfobject-container { height: 30rem; border: 1rem solid rgba(0,0,0,.1); }

    button {
  cursor: pointer;
}

.btn {
  box-sizing: border-box;
  display: inline-block;
  border: 0;
  text-align: center;
  vertical-align: top;
  width: 40px;
  height: 40px;
  line-height: 30px;
  margin: 5px;
  box-shadow: 0 0 10px 0px var(--color6);
}

.btn-big-inner:focus,
.btn:focus {
  outline: none;
}

.block-channel {
  width: 159px;
}
.block-navigate,


.channel{
  background-color: #1c1c1c;
  color: #eee;
  font-size: 35px;
  font-weight: bold;
  border-radius: 4px;
}
.active-channel {
  color: #239B56;
}
</style>