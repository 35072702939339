<script>
    import HiloPreview from './HiloPreview.svelte'
    import globalStore from '../../globalStore'
    import {Ripple} from 'svelte-mui/'
    import {fly} from 'svelte/transition'
    import InfiniteLoading from 'svelte-infinite-loading';
    import {HubConnectionBuilder} from '@microsoft/signalr'
    import RChanClient from '../../RChanClient';
    import Signal from '../../signal';
    import { localStore } from '../../localStore';
    import ajustesConfigStore from '../Dialogos/ajustesConfigStore'
    import AccionesRapidas from '../Moderacion/AccionesRapidas.svelte';

    import {writable} from 'svelte/store';
    export const posts = writable([]);
    export const timeout = writable(false);


    export let hiloList
    export let noCargarNuevos = false

    hiloList.categoriasActivas == hiloList.categoriasActivas ||  $ajustesConfigStore.palabrasHideadasglobalStore.categoriasActivas.includes(hilo.categoriaId) //??quitado

    localStorage.setItem('encriptedTk', hiloList.encriptedTK);

    localStorage.setItem('userIdLocal',hiloList.userIdLocal);   
    
    $:hiloList.hilos = hiloList.hilos.filter(h => !estaOculto(h))
    

    $:usuario = $globalStore.usuario

    let nuevoshilos = []

    Signal.subscribirAHome()
    Signal.coneccion.on("HiloCreado", onHiloCreado)
    Signal.coneccion.on("HiloVisitado", onHiloVisitado)
    Signal.coneccion.on("HiloComentado", onHiloComentado)
    Signal.coneccion.on("HilosEliminados", (ids) => {

        hiloList.hilos = hiloList.hilos.filter(h => !ids.includes(h.id))
        nuevoshilos = nuevoshilos.filter(h => !ids.includes(h.id))
    })

    Signal.coneccion.on("categoriaCambiada", (data) => {
        let hilo = hiloList.hilos.filter((h) => h.id == data.hiloId);
        if (hilo.length != 0) {
            hilo[0].categoriaId = data.categoriaId;
            hiloList.hilos = hiloList.hilos;
        }
        hilo = nuevoshilos.filter((h) => h.id == data.hiloId);
        if (hilo.length != 0) {
            hilo[0].categoriaId = data.categoriaId;
            nuevoshilos = nuevoshilos;
        }
        if (!hiloList.categoriasActivas.includes(data.categoriaId)) {
            hiloList.hilos = hiloList.hilos.filter((h) => h.id != data.hiloId);
            nuevoshilos = nuevoshilos.filter((h) => h.id != data.hiloId);
        }
    });

    function onHiloCreado(hilo) {
        if(noCargarNuevos) return;
        if(hiloList.categoriasActivas.includes(hilo.categoriaId)
        ){
            nuevoshilos = [hilo, ...nuevoshilos]
        }
    }
    function onHiloComentado(id, comentario) {

        let hiloComentado = hiloList.hilos.filter(h => h.id == id)
        if(hiloComentado.length != 0) {
            hiloComentado[0].cantidadComentarios += 1
        }
        

        hiloList = hiloList
    }

    function onHiloVisitado(id, vistas) {
        let hiloComentado = hiloList.hilos.filter(h => h.id == id)
        if(hiloComentado.length != 0) {
            hiloComentado[0].vistas =vistas
        }
        hiloList = hiloList
    }

    function cargarNuevos() {
        let stickies = []

        while (hiloList.hilos.length != 0 && hiloList.hilos[0].sticky != 0) {
            stickies.push(hiloList.hilos.shift())
        }      

        hiloList.hilos = [...stickies,...nuevoshilos,...hiloList.hilos]
       

        nuevoshilos = []

        window.document.body.scrollTop = 0
        window.document.documentElement.scrollTop = 0
    }

    async function cargarViejos({ detail: { loaded, complete }}) {
        if(hiloList.hilos.length < 30) {
            complete(); 
            return;
        }
        
        try {
            let {data} = await RChanClient.cargarMasHilos(hiloList.hilos[hiloList.hilos.length -1].bump, hiloList.categoriasActivas)          
            hiloList.hilos = [...hiloList.hilos, ...data]
            if(data.length == 0) {
                complete()
            }
            loaded()
          
        } catch {
            complete()
        }
    }

    if(!$ajustesConfigStore.palabrasHideadas) $ajustesConfigStore.palabrasHideadas = ""
    let palabrasHideadas = $ajustesConfigStore.palabrasHideadas
        .toLowerCase()
        .split(" ")
        .map(p => p.trim())
        .map(p => p.replace(/\_/g, " "))
        .filter(p => p)
    
    function estaOculto(hilo) {       
        if(hilo.titulo == undefined) return false;
        let titulo = hilo.titulo.toLowerCase()
        for (const palabra of palabrasHideadas) {
            if(titulo.includes(palabra)) return true;
        }
        return false
    }

    let tienaMas = true
    let accionesRapidas = null

    function hiloCliqueado(params) {
        
    }

</script>

<!-- <AccionesRapidas bind:this={accionesRapidas}/> -->
<ul class="hilo-list">
    {#if usuario.estaAutenticado && nuevoshilos.length > 0}
        <div class="frame cargar-nuevos-hilos" on:click={cargarNuevos} transition:fly|local={{x:100}}>
            <button class="custom-btn btn-15">
                <icon class="fe fe-rotate-cw"  style="margin-right: 8px;"/> 
                Cargar {nuevoshilos.length} {nuevoshilos.length==1? 'topic nuevo':'topics nuevos'}            
            </button>          
            <Ripple/>
        </div>
    {/if}
    {#each hiloList.hilos as hilo (hilo.id)}
        <HiloPreview bind:hilo={hilo} on:click={hiloCliqueado}/>
    {/each}
</ul>
<InfiniteLoading on:infinite={cargarViejos} distance={600}>
    <div slot="noMore" style="text-align: center;padding-top: 1%;">No hay mas hilos, recargar pagina    <icon class="fe fe-refresh-ccw"></icon></div>
    <div style="text-align:center" slot="noResults"></div>
</InfiniteLoading>

<style>
    .cargar-nuevos-hilos {
        position: fixed;
        align-items: center;
        z-index: 9;
        bottom: 16px;
        right: 16px;      
        display: flex;
        height: 60px;
        border-radius: 4px;
    }  
       
        .custom-btn {    
        padding: 20px 24px;       
        font-weight: 500;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
        font-size: large;
        }
            /* 15 */
        .btn-15 {
        color: var(--color7);
        border: 1px solid #ff9aff;
        box-shadow: 1px 3px 20px #ff9aff, 0 0 5px #ff9aff inset;
        z-index: 1;
        }
        .btn-15:after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        right: 0;
        z-index: -1;
        background: #ff9aff;
        box-shadow:
        0 0 20px  #ff9aff;
        transition: all 0.3s ease;
        }
        .btn-15:hover {
        color: #fff;
        }
        .btn-15:hover:after {
        left: 0;
        width: 100%;
        }
        .btn-15:active {
        top: 2px;
        }
</style>