<script>
    import {Textfield, Button, Ripple, Checkbox} from "svelte-mui"
    import RChanClient from "../../RChanClient";
    import Captcha from "../Captcha.svelte";
    import ErrorValidacion from "../ErrorValidacion.svelte";
    import config from "../../config"

    let username = ""
    let password = ""
    let captcha = ""
    let error = null
    let usarToken = false
    let token = ""

    async function accion(e) {       
        try {
            let res = null
            if(!usarToken) {
                res = await RChanClient.logearse(username, password)
            } else {
                res = await RChanClient.restaurarSesion(token)
            }
            // if(res.data.redirect) {
            //     window.location.href = response.data.redirect
            // }
        } catch (e) {
            console.log(e);
            error = e.response.data
            return
        }        
        window.location = "/"
        // location.reload();
    }


</script>
<div class="fondo"></div>

<main>   

    <section >
         <ErrorValidacion {error}/>
        <h1>Hola anon!</h1>
        <h2>Para crear y responder topics en Ufftopia debes iniciar una sesión</h2>
        <h3>Si no tenés un token o una cuenta podés <a href="/Inicio"style="color:var(--primary) ">Iniciar Sesión</a> ó <a href="/Registro"style="color:var(--primary) ">registrate</a> </h3>

        
        <form on:submit|preventDefault={accion}>
            <Checkbox bind:checked={usarToken}>Usar Token</Checkbox>
            {#if !usarToken}            
                <Textfield
                autocomplete="off"
                label="Nombre de usuario"
                required
                bind:value={username}
                message="kikefoster4000"
                />
                <Textfield
                    autocomplete="off"
                    label="Contraseña"
                    type="password"
                    required
                    bind:value={password}
                    message="aynose1234"
                />
                {:else}
                    <Textfield
                    autocomplete="off"
                    label="Token de sesion"
                    bind:value={token}
                    />
                {/if}

                <div style="display:flex; justify-content: center;">
                    <Button >Entrar</Button>
                </div>
        </form>
    </section>
</main>

<style>

    main {
        margin:auto;
        height: auto;
        padding: 16px;
        max-width: 1600px;
        margin-top: 80px;
        overflow: hidden;
    }

    section {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        background: var(--color2);
        padding: 16px ;
        border-radius: 4px;
        border-top: solid 2px var(--primary);
    }
    form {
        color: white !important;
        /* background: var(--color2); */
    }

    video {
        position: fixed;
        z-index: -1;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: calc(100vh - 400px);
        width: auto;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0.4;
        /* filter: contrast(1.5) brightness(1.5); */
    }

    .fondo{
        position: fixed;
        top:0;
        left:0;
        width: 100vw;
        height: 100vh;
        z-index: -100;       
        background-size: cover !important;
    }

    :global(.label) {
        color: #ffffffcc !important
    }
</style>