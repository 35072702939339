<script>
    import { createEventDispatcher } from 'svelte';
    import { Button, Checkbox} from 'svelte-mui'
    import comentarioStore from './comentarioStore'
    import RChanClient from '../../RChanClient'
    import ErrorValidacion from '../ErrorValidacion.svelte';
    import MediaInput from '../MediaInput.svelte';
    import Spinner from '../Spinner.svelte';
    import config from '../../config';
    import globalStore from '../../globalStore';
    import votoStore from '../Comentarios/votoStore';

    let dispatch = createEventDispatcher()

    export let hilo

    let cargando = false

    $comentarioStore
    $votoStore

    let media
    let mediaInput

    let mostrarRango = false
    let mostrarNombre = false

    let espera = 0
    $: if(espera != 0) {
        setTimeout(()=> espera--, 1000)
    }
    let error = null

    async function crearComentario() {     
        
        if(espera != 0 || cargando) return
        
        if($globalStore.usuario.esMod && mostrarNombre){
            if (!confirm('ATENCION ESTAS POR FIRMAR COMO ADMIN, DESEAS CONTINUAR?')) {
                    return;            
            }
        }
        
        try {
            cargando = true
            if($globalStore.usuario.esMod || $globalStore.usuario.esAuxiliar && config.general.modoSerenito){
                if($votoStore != null && $votoStore != ""){
                    await RChanClient.crearComentario(hilo.id, $votoStore+ '[-1_7-]' + $comentarioStore, media.archivo, media.link, "", mostrarNombre, mostrarRango)
                }else{
                    await RChanClient.crearComentario(hilo.id, $comentarioStore, media.archivo, media.link, "", mostrarNombre, mostrarRango)
                }
                
                $votoStore = '';
                
            } else {
                if($votoStore != null && $votoStore != ""){                  
                    await RChanClient.crearComentario(hilo.id, $votoStore+ '[-1_7-]' + $comentarioStore, media.archivo, media.link)
                }else{
                    await RChanClient.crearComentario(hilo.id, $comentarioStore, media.archivo, media.link)
                }          
                $votoStore = '';      
            }
            if(!$globalStore.usuario.esMod) {
                espera = config.general.tiempoEntreComentarios
            }
            mediaInput.removerArchivo()
            dispatch("comentarioCreado")
        } catch (e) {
            error = e.response.data
            cargando = false
            return
        }
        
        cargando = false
        $comentarioStore = ''
        $votoStore = ''
        media.archivo = null
        error = null
    }
    function onFocus() {
        error = null
        if(!$globalStore.usuario.estaAutenticado) {
            window.location = '/Inicio'
        }
    }

</script>

{#if !hilo.historial}

    <form on:submit|preventDefault={''} id="form-comentario" method="POST" class="form-comentario panel">


        <ErrorValidacion {error}/>
        {#if hilo.encuesta}  
            <div class="voto svelte-1bl5dia">
                Voto encuesta: {$votoStore}
            </div>    
        {/if}    
        <textarea 
            id="textareaComentario"          
            on:focus={onFocus} 
            bind:value={$comentarioStore} 
            cols="10" 
            rows="10" placeholder="..."
            ></textarea>
    
        <div>
    
            <button class="mousechange" type='submit' style="border-radius: 15px; border-color:pink;color:var(--color5);background-color:transparent; font-size: larger; font-weight: 900;width: -webkit-fill-available;height: 40px;width: 100%;" disabled={cargando} color="primary" on:click={crearComentario}>
                <Spinner {cargando}>{espera == 0? 'Comentar': espera}</Spinner>
            </button>     
        </div>
        <div class="acciones">
            {#if $globalStore.usuario.esMod || $globalStore.usuario.esAuxiliar && config.general.modoSerenito}
                <div style=" flex-direction:row; display:flex">
                    <!--Checkbox bind:checked={mostrarRango} right>Lucesitas</Checkbox-->
                    <Checkbox bind:checked={mostrarNombre} right>Nombre</Checkbox>
                </div>
            {/if}
            <MediaInput bind:this={mediaInput} bind:media={media} commands={false} compacto={true}></MediaInput>        
        </div>
    </form>
{/if}

<style>
    .acciones :global(.mra) {
        margin-left: auto;
    }
    .acciones :global(i) {
        margin: 0 !important;
    }
    .form-comentario :global(.media-input) {
        margin-left: 0;
    }   
    .voto{
        font-weight: 700;
        word-break: break-word;
        overflow: hidden;
        color: --color3;
        color: var(--color6);
        background: var(--color4);margin-bottom: 10px;
    }

    .mousechange:hover {
        cursor: pointer;
    }
   
</style>