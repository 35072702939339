<script>
    import { formatearTimeSpan } from '../../helper';
    import Tiempo from '../Tiempo.svelte'
    import Dialogo from '../Dialogo.svelte'
    import RChanClient from '../../RChanClient'
    import BarraModeracion from '../Moderacion/BarraModeracion.svelte';
   
    let resumen = window.model.resumen 
    let categorias
    let uff 
    let omniverso 
    let denuncia 
    let configuracion
    let type 
    let accion    
    let palabra

    let horas

    updateData(); 

    async function aplicar() {
        let res =  await RChanClient
                        .aplicarPalabraFiltro(
                            palabra,
                            type,
                            accion
                        )        
        if(res.data.success == true){
            resumen = res.data.value
            updateData()
        }

    }

    async function cleanOmniverso() {
        if (!confirm('ATENCION CONFIRMA APRETAR BOTON PANICO?')) {
             return;            
         }
        
        let res =  await RChanClient .cleanOmniverso(horas)  
        if(res.data.success == true){
            alert("limpieza ok");
        }else{
            alert("fallo limpieza");
        }
    }

    async function cleanGlobales() {
        if (!confirm('ATENCION CONFIRMA RESET GLOBALES?')) {
                    return;            
         }
        let res =  await RChanClient.cleanGlobales()  
        if(res.data.success == true){
            alert("limpieza ok");
        }else{
            alert("fallo limpieza");
        }
    }

    function updateData(){
        categorias = resumen.split('**');
        
        uff = categorias[0].split(";")
        omniverso = categorias[1].split(";")
        denuncia = categorias[2].split(";")
        configuracion = categorias[3].split(";")
        type = "-1"
        accion = "-1"
        palabra = ""
    } 
</script>

<BarraModeracion/>

<div>
        
    <div style="width:33%">   
   
    <div style="margin:2%">
        <form  id="crear-palabra-form" on:submit|preventDefault
        style="margin: 2%;min-width: 60%;; overflow-y: auto;height: 400px;margin-top: 5%;" >
         <!--Agregar/elimanr palabra-->
         <ul style="width:33%;margin-top: 5%;">
            <h3 style="text-align:center;color: mediumaquamarine;">Agregar valores</h3> 
        </ul>      
        <select style="background: var(--color4);" bind:value={type}  name="type">
            <option value="-1" selected="selected" disabled="disabled">Type</option>
            <option value="0">Denuncia [Agregar o Remover]</option>
            <option value="1">Uff [Agregar o Remover]</option>
            <option value="2">Omniverso [Agregar o Remover]</option>  
            <option value="3">Actualizar Cantidad Denuncias para Revision [Idem]</option> 
            <option value="4">Cerrar Comentarios [Idem]</option> 
            <option value="5">Cerrar Registros [Idem]</option> 
            <option value="6">Cerrar Creacion Hilos [Idem]</option>          
            <option value="7">Cambiar Nivel Seguridad [Idem]</option>       
            <option value="8">Actualizar IdHilo-Inicial [Idem]</option>        
            <option value="9">Actualizar Tiempo Nuevo-Usuario [Idem]</option>    
            <option value="10">Actualizar Cerar Autom. Madrugada [Idem]</option>
            <option value="11">Poner Url Tv Room Default [Idem]</option>    
            <option value="12">Actualizar lista Canales [Idem]</option>    
        </select>   
        <select style="background: var(--color4);" bind:value={accion} name="accion">
            <option value="-1" selected="selected" disabled="disabled">Acción</option>
            <option value="0">Agregar [texto]</option>
            <option value="1">Remover [texto]</option>  
            <option value="2">Actualizar Cantidad Denuncias para Revision [numerico]</option> 
            <option value="3">Cerrar Comentarios [si,no]</option> 
            <option value="4">Cerrar Registros [si,no]</option> 
            <option value="5">Cerrar Creacion Hilos [si,no]</option> 
            <option value="6">Cambiar Nivel Seguridad [Off, EssentiallyOff,Low, Medium,High,UnderAttack]</option> 
            <option value="7">Actualizar IdHilo-Inicial [texto]</option>
            <option value="8">Actualizar Tiempo Nuevo-Usuario [texto]</option> 
            <option value="9">Actualizar Cerar Autom. Madrugada [si,no]</option>       
            <option value="10">Poner Url Tv Room Default [Texto]</option>                   
            <option value="11">Actualizar lista Canales [Texto]</option>    
        </select> 
        
        <input name="palabra" bind:value={palabra} style="background: var(--color4);" placeholder="escribir valor">
        <button on:click={aplicar} type='submit' style="border-radius: 15px; border-color: pink;color:var(--primary);background-color:transparent; font-size: larger; font-weight: 900;width: -webkit-fill-available;height: 40px;width: 100%;" color="primary">
           Aplicar
        </button>   

            <ul>
                <h3 style="text-align:center;color: mediumaquamarine;margin-top: 5%;">Palabras filtradas</h3> 
            </ul>
            <!--lista denuncia-->
            <div style="margin:2%">
                <table>            
                    <tbody>
                        {#each denuncia as row}
                            <tr>
                                <td>{row}</td>                        
                            </tr>
                        {/each}
                    </tbody>
                </table>
            </div>
        <!--lista uff-->
            <div style="margin:2%">
                <table>            
                    <tbody>
                        {#each uff as row}
                            <tr>
                                <td>{row}</td>                        
                            </tr>
                        {/each}
                    </tbody>
                </table>
            </div>
         <!--lista omni-->
            <div style="margin:2%">
                <table>            
                    <tbody>
                        {#each omniverso as row}
                            <tr>
                                <td>{row}</td>                        
                            </tr>
                        {/each}
                    </tbody>
                </table>
            </div>
        <!--lista configuracion-->
        <ul>
            <h3 style="text-align:center;color: mediumaquamarine;margin-top: 5%;">configuracion</h3> 
        </ul>
        <div style="margin:2%">
            <table>            
                <tbody>
                    {#each configuracion as row}
                        <tr>
                            <td>{row}</td>                        
                        </tr>
                    {/each}
                </tbody>
            </table>
        </div>

        <button on:click={cleanOmniverso} type='submit' style="border-radius: 15px; border-color: pink;color:var(--primary);background-color:transparent; font-size: larger; font-weight: 900;width: -webkit-fill-available;height: 40px;width: 100%;" color="primary">
             PANIC BOTTOM
         </button>   
         <input bind:value={horas} style="background: var(--color4);" placeholder="horas a resetear">
         <br>
         <br>
         <button on:click={cleanGlobales} type='submit' style="border-radius: 15px; border-color: pink;color:var(--primary);background-color:transparent; font-size: larger; font-weight: 900;width: -webkit-fill-available;height: 40px;width: 100%;" color="primary">
            LIMPIAR GLOBALES
        </button>
        </form>
    </div>
   </div>
</div>
<style>
	table, th, td {
		border: 1px solid black;
		border-collapse: collapse;
	}
	table {
		background: var(--color1);
		width: 50%;
		text-align: center;
	}

    #crear-palabra-form {
    position: fixed;
    height: fit-content;
    z-index: 1;
    width: 100vw;
    max-width: 465px;   

}
</style>