<script>
    import { formatearTimeSpan } from '../../helper';
    import Tiempo from '../Tiempo.svelte'
    import Dialogo from '../Dialogo.svelte'
    import RChanClient from '../../RChanClient'
    import BarraModeracion from '../Moderacion/BarraModeracion.svelte';
    import { paginate, LightPaginationNav } from 'svelte-paginate'
    import {Icon, Button, Ripple} from "svelte-mui"

    let ultimosRegistros = window.model.mappedUsers
    let ultimosBaneos = window.model.ultimosBaneos
    let cantidadDeUsuarios = window.model.cantidadDeUsuarios

    function mostrarTodos(){
        pageSize = 99999999
   }

   function mostrarTodosBan(){
    contadorBaneo = itemsBaneos.length
   }

  let items = ultimosRegistros
  let currentPage = 1
  let pageSize = 100
  $: paginatedItems = paginate({ items, pageSize, currentPage })

  let contadorBaneo = 50
  let itemsBaneos = ultimosBaneos
 

</script>

<BarraModeracion/>
<section>
    <div class="lista-baneos panel">
        <Button style="border-style: dotted;" on:click={mostrarTodosBan}>Mostrar Todos</Button>
        <h2>Baneos activos</h2>
        <h4>Hay un total de {itemsBaneos.length} usuarios baneados</h4>
        <br>    
        <ul>
            {#each {length: contadorBaneo} as _, i } {itemsBaneos[i]}    

            {contadorBaneo = contadorBaneo +1}

            <li class="ban" >
                <a 
                style="color:var(--color6)" 
                    href="/Moderacion/HistorialDeUsuario/{itemsBaneos[i].usuario.id}">
                    {itemsBaneos[i].usuario.userName}</a>
                    fue baneado hace <Tiempo date={itemsBaneos[i].creacion}/> <br>
                 
                    <a style="color:var(--color6)" href="/Hilo/{itemsBaneos[i].hiloId}#{itemsBaneos[i].comentarioId}">hilo/comentario del baneo</a>
                    Duracion: {formatearTimeSpan(itemsBaneos[i].duracion)}
                    {#if itemsBaneos[i].flag != null} 
                    , localidad:  <span style="color:cornflowerblue"> {itemsBaneos[i].flag} </span>                    
                    {/if}
                    ,  IP <span style="color:cornflowerblue"> {itemsBaneos[i].ip}
                        ,  BAN-ID <span style="color:cornflowerblue"> {itemsBaneos[i].id} </span>
                   
                    <br>
                    <Dialogo textoActivador="Desbanear" titulo="Desbanear gordo" accion = {() => RChanClient.removerBan(itemsBaneos[i].id)}>
                        <div slot="body">
                            Remover ban?
                        </div>
                    </Dialogo>
                </li>
              
                {/each}
            </ul>
           <!-- {#if itemsBaneos.length > pageSizeBaneos}
            <LightPaginationNav
                totalItems="{itemsBaneos.length}"
                pageSizeBaneos="{pageSizeBaneos}"
                currentPageBaneos="{currentPageBaneos}"
                limit="{1}"
                showStepOptions="{true}"
                on:setPage="{(e) => currentPageBaneos = e.detail.page}"
            />
        {/if}-->
        </div>

    <div class="lista-usuarios panel">
        <Button style="border-style: dotted;" on:click={mostrarTodos}>Mostrar Todos</Button>
        <h2>Usuarios registrados</h2>
        <h4>Hay un total de {cantidadDeUsuarios} usuarios registrados</h4>
        <br>       
        <ul>
            {#each paginatedItems as u}  
                <li style="padding:4px 8px">Se registro 
                    <a style="color:var(--color6)" href="/Moderacion/HistorialDeUsuario/{u.id}">{u.userName}</a>  
                    hace <span style="color:blueviolet"><Tiempo date={u.creacion}/></span> 
                    
                    {#if u.flag != null} 
                    , localidad:  <span style="color:cornflowerblue"> {u.flag} </span>                    
                    {/if}
                    ,  IP <span style="color:cornflowerblue"> {u.ip} </span>
                    ,  Token <span style="color:blueviolet"> {u.token} </span>
                </li>
            {/each}
        </ul>

        {#if ultimosRegistros.length > pageSize}
        <LightPaginationNav
            totalItems="{items.length}"
            pageSize="{pageSize}"
            currentPage="{currentPage}"
            limit="{1}"
            showStepOptions="{true}"
            on:setPage="{(e) => currentPage = e.detail.page}"
        />
      {/if}
    </div>
</section>

<style>
    li{
        border-style: groove;
        border-color: bisque;
    }
    .ban {
        padding:4px 8px;
        background: var(--color4);
        margin-bottom: 4px;
        border-radius: 4px;
    }

    section {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: flex-start;
    }

    section>div {
        max-width: 500px;
    }
</style>