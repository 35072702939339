export default class MediaType
{
    static Imagen = 0
    static Video = 1
    static Youtube = 2
    static Tv = 4
    static Pdf = 5
    static Dailymotion = 6
    static Bitchute = 7
    static ArchiveOrg = 8
    static Spotify = 9
    static Jdoodle = 10
    static Coub = 11
    static OkRusia = 12

}