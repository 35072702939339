export default [
    {
        nombre: 'Classic',
        style: `
        #fondo-global {
            background: var(--color2);
        }`
        
    },
    {
        nombre: 'Legacy',
        style: `body {
            --color2: #2c3e50;
            --color5: #256587;
            --color6:#256587;
            --color7:#2c3e50; 
            --color8: #256587;   
            --primary:#256587;
        }
        #fondo-global {
            background: #1e2c38;
        }
        
        .comentarios, .side-panel{
            background: #213140 !important;
        }
        .comentario {
            background: #213140
        }
        .sticky-info {
            background: var(--color6) !important;
        }
        
        .comentario:hover {
            background: #2c3b4a !important
        }`
    },
    {
        nombre: 'Legacy 2',
        style: `body {
            --color1: rgb(23, 33, 43);
            --color2: rgb(23 33 43);
            --color3: rgb(24 37 51);
            --color4: rgb(36 47 61);
            --color5: #2196F3;
            --color6: #F8485E;
            --color7: rgb(53 78 103);
            --color8: #2196F3;
            --color-texto1: white;
            --color-texto2: rgb(255 255 255 / 56%);
            --primary: #2196F3;
        }`
    }, 
    {
        nombre: 'Orange',
        style: `body {
            --color1:#4E2508;
            --color2:rgb(20 20 20);
            --color3:rgb(30 30 30);
            --color4:rgb(40 40 40);
            --color5:#FF730F;
            --color6:#fc9208;
            --color7:#603300;   
            --color8:#FF730F;
            --primary:#fc9208;     
        }`
    },
    {
        nombre: 'Green',
        style: `body {
            --color1:rgb(50 64 51);
            --color2:rgb(40, 54, 41);
            --color3:rgb(50 64 51);
            --color4:rgb(60 74 61);
            --color5:green;
            --color6:green;
            --color8:green;
            --primary:green;
        }`
    },
    {
        nombre: 'RED',
        style: `body { 

            --color2: #1A1A1A; 
            
            --color5: #C1272D; 
            
            --color6: #C1272D; 
            
            --color7:#FFFFFF; 
            
            --color8: #C1272D; 
            
            --primary:#C1272D; 
            
            } 
            
            #fondo-global { 
            
            background: #1A1A1A; 
            
            } 
            
            
            
            .comentarios, .side-panel{ 
            
            background: #000000 !important; 
            
            } 
            
            .comentario { 
            
            background: #1A1A1A; 
            
            } 
            
            .sticky-info { 
            
            background: var(--color6) !important; 
            
            } 
            
            .info.svelte-j4cb7u:first-child { 
            
            background: rgb(26, 26, 26) !important; 
            
            } 
            
            .comentarios, .side-panel { 
            
            background: rgb(26 26 26) !important; 
            
            } 
            
            .espacio-vacio.svelte-1y6xyh1 { 
            
            height: 20px; 
            
            } 
            
            .light-pagination-nav.svelte-1qrr50k .pagination-nav { 
            
            background: var(--color5); 
            
            } 
            
            .light-pagination-nav.svelte-1qrr50k .option.active { 
            
            color: rgb(0, 0, 0); 
            
            } 
            
            .btn-15.svelte-sfi2js:after { 
            
            background: #1a1a1a; 
            
            box-shadow: 0 0 20px #1a1a1a; 
            
            } 
            
            .btn-15.svelte-sfi2js { 
            
            border: 1px solid rgb(0 0 0 / 20%); 
            
            box-shadow: 1px 3px 20px rgb(0 0 0 / 20%), 0 0 5px #dd3226 inset; 
            
            } 
            
            .estadisticas.svelte-1tx0ypb span.svelte-1tx0ypb { 
            
            background-image: linear-gradient(180deg,#ffffff 45%,#605151); 
            
            } 
            
            .dado.svelte-gtbft1.svelte-gtbft1 { 
            
            font-family: arial black; 
            
            } 
            
            .comentario.svelte-gtbft1 .texto.svelte-gtbft1 { 
            
            white-space: pre-wrap; 
            
            word-break: break-word; 
            
            overflow: hidden; 
            
            display: inline; 
            
            }`
    },
    {
        nombre: 'KHAKI',
        style: `body {
            color: #000000;
            background-color: #b0a791;
            --color1: #b3a685!important;
            --color2: #d7d7c4!important;
            --color3: #d7d7c4;
            --color4: #c6c6a9;
            --color5: #91814e!important;
            --color6: #fc9208;
            --color7: #b9b995;
            --color-texto1: #000000;
            --color-texto2: #1269cc;
        }
         
        .hilo-completo.svelte-1ffcrgt{
            background-color: #b5ab92;
            font-family: 'Open Sans', sans-serif;
            font-size: 17px;
        }
         
        .comentario a, .hilo-completo .contenido a {
            color: #838308!important;
        }
         
        .oculta.svelte-1ffcrgt.svelte-1ffcrgt, .oculta.svelte-xtzjgi .colapsar-categorias.svelte-xtzjgi{
            background-color: #b5ab92;
        }
         
         
         
        #fondo-global {
        background:  #838308;
        }
         
        .menu-principal-header {
            background: #afafa3;
        }
         
        .menu-principal ul li.grupo-categorias-activo {
            background: #b9b995;
        }
         
        .comentario.svelte-8sy01i .tag.svelte-8sy01i,
        .comentario.svelte-gtbft1 .tag.svelte-gtbft1{
            background: #b5b5b5ad;
            font-size: 11px;
        }
         
         
        .comentario.svelte-8sy01i .texto.svelte-8sy01i ,
        .comentario.svelte-gtbft1 .texto.svelte-gtbft1
        {
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
        }
         
        .media-input, .menu-principal-header {
            background: url(https://images2.imgbox.com/bb/28/l2cNBIJp_o.jpg) !important;
            background-size: cover !important;
        }
         
        #svelte-navbar>header>nav>div.nav-principal{
            background:linear-gradient(90deg,var(--color4),var(--color2),var(--color5))!important; 
        }
         
        .hilo>.hilo-in::after{
            content:"";
            background-size:cover;
            top:0;
            left:0;
            width:100%;
            height:100%;
            position:absolute;
        }
         
        h3{
            color: #2f1306;
        }
         
        .verde{
            color: #0b5014;
            font-weight: bold;
        }
         
        input, textarea, select, option{
            color: #1269cc;
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
            background-color: #c5c7a9;
        }
         
        #fondo-global, .menu-principal-header, .media-input{
            background-color: #b5ab92!important ;
        }
         
        .noti-cont, .badge{
            color: #1269cc;
            background-color: #b3a685;
            font-weight: bold;
        }
         
        .acciones.svelte-1ffcrgt .mra {
            color: #838308;
            font-weight: bold;
        }
         
        form.svelte-1ffcrgt {
            color: #fff!important;
            background-color: #727272;
        }
         
        .crear-hilo-boton.svelte-1ffcrgt.svelte-1ffcrgt{
            padding: 16px;
            color: #f1f1f1;
        }
         
        .opcion.svelte-bhomuy{
            background-color: #c4c4c4;
        }
         
        .voto.svelte-bhomuy.svelte-bhomuy,
        .voto.svelte-gtbft1.svelte-gtbft1{
            color: var(--color-texto2);
            font-family: 'Open Sans', sans-serif;
         
        }
         
        .cuerpo.svelte-85mmj5.svelte-85mmj5,
        .cuerpo.svelte-h5pjmo .svelte-h5pjmo,
        .custom-btn.svelte-glur25.svelte-glur25{
            font-family: 'Open Sans', sans-serif;
        }
         
         
        body::-webkit-scrollbar-thumb, .fondo5, .info, .cargar-nuevos-hilos {
            background-color: #91814e ;
        }
         
        ::-webkit-scrollbar {
            width: 12px;
        }
        }`
    },    
    {
        nombre: 'Porche',
        style: `body {
            --color5: orangered;
            --color2: white;
            --color-texto1: black;
            --color-texto2: orangered;
            --color4: orangered;
            --color7: #ffc253ed;
            --color8: orangered;
        }
        
        .media-input {
            background: orangered !important;
        }
        
        .comentario .id {
            background: black !important;
        }
        
        .crear-hilo-boton, .categoria, .comentario .tag, comentario, .media-input, .cuerpo, .respuestas-compactas {
            color: white !important;
        }
        
        .comentario {
            background: #ffffffdb;
        }
        .comentario:hover {
            background: #ffeaeadb !important;
        }
        
        .menu-principal-header {
            background: #fff;
        }
        .menu-principal,
        .menu-principal h1,
        .menu-principal-header {
            color: orangered;
        }
        
        #fondo-global {
            background-image: url(https://www.carpixel.net/w/4ea482b90e147057f9d64b18d765772e/porsche-911-gt3-r-hybrid-wallpaper-hd-49401.jpg) !important;
            
        }

        .media-input, .menu-principal-header {
            background-image: url(https://www.carpixel.net/w/4ea482b90e147057f9d64b18d765772e/porsche-911-gt3-r-hybrid-wallpaper-hd-49401.jpg) !important;
            background-size: cover !important;
        }
        textarea {
            border: 2px solid var(--primary);
            background: white !important
        }
        .cuerpo {
            color:black !important;
        }`
    },     
    {
        nombre: 'Romed',
        style: `body {
            --color5: #db9124;
            --color2: #6c1108;
            --color-texto1: #db9124;
            --color-texto2: #db9124;
            --color4: #a62416;
            --color7: #e9e3d6;
            --color8: #db9124;
        
        }
        
        .media-input, .menu-principal-header {
            background: url(https://wallpaperaccess.com/full/308552.jpg) !important;
            background-size: cover !important;
            
        }
        
         .menu-principal-header {
            background: url(https://wallpaperaccess.com/full/308552.jpg) !important;
            background-size: cover !important;
            
        }
        
        .comentario .id, .favorita {
            background: #6c1108 !important;
        }
        
        .crear-hilo-boton, .categoria, .comentario .tag, comentario, .media-input, .cuerpo {
            color: white !important;
        }
        
        .comentario {
            background: #c7c0b1;
            color: black
        }
        .comentario:hover {
            background: #e3dccc !important;
        }
        
        .menu-principal-header, .nav-principal {
            background-size: 100%;
            background-position-y: -500px;
        }
        .menu-principal,
        .menu-principal h1,
        .menu-principal-header {
            color: #db9124;
        }
        
        #fondo-global {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: url(https://wallpaperaccess.com/full/308552.jpg);
            background-size: cover;
            background-position-x: -500px;
            z-index: -1;
        }
        
        textarea {
            border: 2px solid var(--primary);
            background: #c7c0b1 !important;
            color: black
        }
        
        .nav-principal h3, .crear-hilo-boton, .cuerpo h1, .version {
            font-family:serif !important;
            text-transform: uppercase  !important;
            font-weight: 800;
        }
        .noti-cont {
            color: white
        }`
    },
    {
        nombre: 'Skin de usuario 1',
        style: ``
    },
    {
        nombre: 'Skin de usuario 2',
        style: ``
    },
    {
        nombre: 'Skin de usuario 3',
        style: ``
    },
    {
        nombre: 'Skin de usuario 4',
        style: ``
    },
    {
        nombre: 'Skin de usuario 5',
        style: ``
    }
]