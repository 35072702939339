<script>
    import {ComentarioEstado} from "../../enums"
    import Comentario from "../Comentarios/Comentario.svelte";
    export let comentario
    export let tituloHilo

    let length = 20;
    
    let tituloTrucated = "";

    if(tituloHilo != null){
        if(tituloHilo != null){
        if(tituloHilo.length>= length ){
            tituloTrucated = tituloHilo.substring(0, length);
        }else{
            tituloTrucated = tituloHilo 
        }
      }
    }
    

    tituloTrucated = tituloTrucated + "..."
</script>
    <div style="margin-top: 20px;"
        class:eliminado = {comentario.estado == ComentarioEstado.eliminado}
    >
        <span style="border-style: groove;border-color:#fc9208;"> {comentario.ip}</span> 
        <span ><img  src={comentario.flag ? comentario.flag.split(";")[0]:""} alt="pais" class="tag-flag"></span>        
        <span style="border-style: groove;border-color:aquamarine;"> {comentario.flag ? comentario.flag.split(";")[1]:""}</span>
        <span style="border-style: groove;border-color:red;"> {tituloTrucated}</span>
       
        <Comentario hilo={{id: comentario.hiloId}} {comentario}/>
    </div>

<style>
    /* .eliminado {
        border-left: solid 2px red;
    } */
    .tag-flag{
        border-style: groove;
        width: 25px;
        border-radius: 8px;
    }
</style>