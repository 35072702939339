<script>
  import { Ripple, Button, Icon } from 'svelte-mui'
  import {abrir} from '../Dialogos/Dialogos.svelte'
  import Menu from '../Menu.svelte'
  import config from "../../config"
  import globalStore from '../../globalStore'
  import MediaType from "../../MediaType"
  import {fly} from "svelte/transition"
  import { createEventDispatcher } from 'svelte';
  
  import more from '../../icons/more-vertical.svg'
  import RChanClient from '../../RChanClient';
  import Dado from '../Dado.svelte'
  import Signal from '../../signal';
  import { onMount } from 'svelte';
  import ajustesConfigStore from '../Dialogos/ajustesConfigStore'

  export let hilo
  
  let dispatch = createEventDispatcher();     
  let media = hilo.media
  let destellando = false
  let visible = true
  
  let mostrarMenu = false
  let heigt 
  let cantidadComentarios
  let vista 

     $: cantidadComentarios = hilo.cantidadComentarios     
      media = hilo.media
      vista = media.vistaPreviaCuadrado   
      let vistaJpg = media.vistaPreviaCuadrado.split('.') [0]+ ".jpg" 
      if(!hilo.cantidadComentarios) hilo.cantidadComentarios = 0;
      if(!hilo.vistas) hilo.vistas = 0;  

  let recienCargado = true

  if ($globalStore.usuario.esMod || $globalStore.usuario.esAuxiliar){
      heigt = '200px'
  }else{
      heigt = '-webkit-fill-available' 
  }
      
     
  $: destellar(cantidadComentarios)
  function destellar(cantidadComentarios) {
    
      if(recienCargado){
          recienCargado = false
          return
      }

      destellando = true
      setTimeout(() => destellando = false, 2000)
  }
  
  async function toggle() {
      visible = !visible;
      if($globalStore.usuario.estaAutenticado){
          await RChanClient.agregar('ocultos', hilo.id)
      } else{
  
      }
  }
  
  function restImg(id) {     
       return RChanClient.restaurarImagen(id, 1);       
    }
    
  let isDisabled = false;

  function onClick(e) {
      // Permitir la apertura en nueva pestaña/ventana con Ctrl/Cmd o clic del botón del medio
    if (e.ctrlKey || e.metaKey || e.button === 1) {
      return;  // Permitir que el navegador maneje la acción sin interferencias
    }

    e.preventDefault();  // Previene la redirección por defecto del enlace

    if (!isDisabled) {
      isDisabled = true;
      // Redirige manualmente, bloqueando múltiples clics
      window.location.href = e.currentTarget.href;
    }
      
  }
  
  
  function onContextMenu(e) {
      if(!e.ctrlKey)return;
      e.preventDefault()
      toggle()
  }

  
  let vistaPreview

  let showIframe = false
  function onShowPreview(e) {
      let result = media.url.includes(".gif");                  
       if((media.tipo == 0 && result)){        
        vista = "/Media/"+media.url        
        showIframe = false;
        vistaPreview = null
      }else{
        showIframe = false;
        vistaPreview = null
      }
  }    

  function onShowPreviewTouch(e) {
      let result = media.url.includes(".gif");        
      if((media.tipo == 0 && result)){        
        vista = "/Media/"+media.url        
        showIframe = false;
        vistaPreview = null
      }else{
        showIframe = false;
        vistaPreview = null
      }
  }    
           
  function onExitPreview(e) {
      mostrarMenu = false;
      showIframe = false
      vista = media.vistaPreviaCuadrado  
      vistaPreview = null     
  }
  
  Signal.coneccion.on("BloquearHilo", onComentarioHilo)
  
  function onComentarioHilo(hiloId) {        
       if(hilo.id == hiloId){
          var img = document.getElementById(hilo.id);
          img.style.filter = 'blur(20px)'; 
         
       }
  }

  function onFocus() {        
       
  }

  function setHegit(){      
    var height = document.getElementById(hilo.id).offsetHeight;
 
     var elementFront = document.getElementById(hilo.id+'front');         
     elementFront.style.height= height+ 'px';

     var elementBack = document.getElementById(hilo.id+'back');        
     elementBack.style.height= height+ 'px';;    
 }

 
onMount(() => {

    if (!$ajustesConfigStore.flip) return ;
    setHegit();    
})

  </script>
  
 {#if visible && hilo.id != undefined}
  <li class="hilo"
      on:mouseover={onShowPreview}
      on:touchstart ={onShowPreviewTouch}
      on:mouseleave={onExitPreview}
      on:touchend ={onExitPreview}
      on:contextmenu={onContextMenu}
      on:focus={onFocus}
      >
      <div class="" style="top: 0;
      right: 0;
      z-index: 232;
      display: flex;
      flex-direction: column;
      position: absolute;">
          <Menu>
              <span slot="activador">
                  <Button  icon color="white" style="margin-left: auto;"  on:click={()=> mostrarMenu = !mostrarMenu}>
                      <Icon><svelte:component this={more} /></Icon> 
                      <!-- <i class="fe fe-circle"></i> -->
                  </Button>
              </span>
              <li on:click={toggle}>Hide/Show <Ripple/></li>
              <li on:click={() => abrir.reporte(hilo.id, "")}>Reportar <Ripple/></li>
              {#if $globalStore.usuario.esMod || $globalStore.usuario.esAuxiliar}
                  <li on:click={() => abrir.cambiarCategoria(hilo.id)} >Categoria <Ripple/></li>
                  <li on:click={() => abrir.eliminarHilo(hilo.id, "")} >Eliminar <Ripple/></li>
                  <li on:click={() => abrir.ban(hilo.id)} >Banear <Ripple/></li>
                   <li on:click={() => restImg(hilo.id)} >Restaurar Media <Ripple/></li>
              {/if}
          </Menu>
      </div>
      {#if !$ajustesConfigStore.flip}
          <a id="{hilo.id}" style="background:url({vista}), url({vistaJpg}); background-repeat: round;cursor: pointer;"  class="hilo-in" on:click={onClick} href="/Hilo/{hilo.id}">
            <!-- <a  href="#asf" class="hilo-in" :bind:id={hilo.id}}> -->
                              
                {#if destellando}
                    <div class="destello"></div>
                {/if}
                <!-- <img src={media.vistaPreviaCuadrado} alt="{hilo.titulo}" class="imghilo"> -->
                <div class="infos">
                    {#if hilo.sticky > 0} <div class="info sticky-info"><Icon size="17" path="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12M8.8,14L10,12.8V4H14V12.8L15.2,14H8.8Z" /></div>{/if}
                    {#if hilo.nuevo} <div class="info" style="background:#ba0c84">NEW</div>{/if}
                    <div class="info" style="">{(config.categoriaPorId(hilo.categoriaId)|| {nombreCorto:"??"}).nombreCorto}</div> <!--//??quitado-->                                         
                    <div class="info"><icon class="fe fe-eye"></icon>{hilo.vistas}</div>  
                    <div class="info"><icon class="fe fe-edit"></icon>{hilo.cantidadComentarios}</div>
                        
                </div>
                <div>
                    <div><h3>{hilo.titulo}</h3></div> 
                    <div class="infos" style="background: #00000073;"></div>                    
                </div>
                
            </a>
        {:else}
          <a id="{hilo.id}" href="/Hilo/{hilo.id}" class="hilo-in" on:click={onClick} >
          <!-- <a  href="#asf" class="hilo-in" :bind:id={hilo.id}}> -->
            {#if destellando}
            <div class="destello"></div>
             {/if}         
              <div class="box-item">
                  <div class="flip-box">
                    <div id="{hilo.id}front" class="flip-box-front text-center" style="background-size: cover; background-image: url({vista});">
                      <div id="div1">
                        <div class="infos"> {#if hilo.sticky > 0} 
                                  <div class="info sticky-info">
                                        <Icon size="17" path="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12M8.8,14L10,12.8V4H14V12.8L15.2,14H8.8Z" />
                                    </div>{/if}
                                            {#if hilo.nuevo} 
                                    <div class="info" style="background:#ba0c84">NEW</div>{/if}
                                            
                                    <div class="info" style="">{(config.categoriaPorId(hilo.categoriaId)|| {nombreCorto:"??"}).nombreCorto}</div>
                                    <!--//??quitado-->
                                    <div class="info">
                                        <icon class="fe fe-eye"></icon>{hilo.vistas}
                                    </div>
                                    <div class="info">
                                        <icon class="fe fe-edit"></icon>{hilo.cantidadComentarios}
                                    </div>
                                </div>
                      </div>
                      <div id="div2">
                          <div>
                              <h3>{hilo.titulo}</h3>
                          </div>
                      </div>
                            <div id="{hilo.id}back" class="flip-box-back text-center" style="background-size: cover;background-image: url({vista});">
                              <div id="div3"></div>
                              <div id="div4">
                                <div>
                                    <h3 class="h3-contenido">{hilo.contenido.replace(/(\r\n|\n|\r)/gm, "").substring(0, Math.min(75,hilo.contenido.length)).toLowerCase()+ "..."}</h3>                                    
                                    </div>
                                </div>
                              </div>
                            </div>
                      </div>
                      <div class="box-item"></div>     
          </a>
          {/if}
      </li>
  {/if}
  
  
  
  <style>
      .info {
          border-radius: 0 !important;
          margin: 0;
          height: 18px!important;
      }
      
      .info:first-child {
          border-radius: 50px 0 0 50px!important;
      }
      .info:last-child {
          border-radius: 0 50px 50px 0!important;
          padding-left: 0;
      }
      .sticky-info {
          background: var(--color1)
      }
      
    
      .hilo :global(button) {
          border-radius:  50% 0% 0% 50%;
      }
      .encuesta {
          background: var(--color6);
          padding: 0 !important;
          margin: 0 2px;
          width: 18px;
          display: flex;
          justify-content: center;
          border-radius: 2px !important;
      }
  
      /* Nuevo estilo */
      /* .infos {
          padding-top: 0;
      }
      .info:first-child {
          border-radius: 0px 0 0 7px!important;
      }
      .info:last-child {
          border-radius: 0 0px 7px 0!important;
      }
       */
     
*, *:before, *:after {
box-sizing: inherit;
}

.text-center {
text-align: center;
}

.color-white {
color: #fff;
}

@media screen and (min-width:1380px) {
.box-container {
  flex-direction: row
}
}

.flip-box {
-ms-transform-style: preserve-3d;
transform-style: preserve-3d;
-webkit-transform-style: preserve-3d;
perspective: 1000px;
-webkit-perspective: 1000px;
}

.flip-box-front,
.flip-box-back {
background-size: cover;
background-position: center;
border-radius: 8px;  
-ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
-webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.flip-box-front {
-ms-transform: rotateY(0deg);
-webkit-transform: rotateY(0deg);
transform: rotateY(0deg);
-webkit-transform-style: preserve-3d;
-ms-transform-style: preserve-3d;
transform-style: preserve-3d;
}

.flip-box:hover .flip-box-front {
-ms-transform: rotateY(-180deg);
-webkit-transform: rotateY(-180deg);
transform: rotateY(-180deg);
-webkit-transform-style: preserve-3d;
-ms-transform-style: preserve-3d;
transform-style: preserve-3d;
}




.flip-box-back {
position: absolute;
top: 0;
left: 0;
width: 100%;

-ms-transform: rotateY(180deg);
-webkit-transform: rotateY(180deg);
transform: rotateY(180deg);
-webkit-transform-style: preserve-3d;
-ms-transform-style: preserve-3d;
transform-style: preserve-3d;
}

.flip-box:hover .flip-box-back {

-webkit-transform-style: preserve-3d;
-ms-transform-style: preserve-3d;
transform-style: preserve-3d;
}



.flip-box .inner {
position: absolute;
left: 0;
width: 100%;  
outline: 1px solid transparent;
-webkit-perspective: inherit;
perspective: inherit;
z-index: 2;

transform: translateY(-50%) translateZ(60px) scale(.94);
-webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
-ms-transform: translateY(-50%) translateZ(60px) scale(.94);
top: 93%;
}

.inner-back {
position: absolute;
left: 0;
width: 100%;  
outline: 1px solid transparent;
-webkit-perspective: inherit;
perspective: inherit;
z-index: 2;

transform: translateY(-50%) translateZ(60px) scale(.94);
-webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
-ms-transform: translateY(-50%) translateZ(60px) scale(.94);
top: 93%;
}
.h3-contenido {
  line-height: 1.1em;
  text-shadow: 2px 2px 2px rgb(10 10 10 / 90%);
  font-size: 19px;
  padding: 4px;
  padding: 0 8px 15px;
  background: linear-gradient(0deg,#00000073 70%,transparent);
  grid-row: 3;
  grid-column: 4/1;
  color: #fff;
  margin: 0;
  font-weight: 500;
  font-family: system-ui;
}

#div1, #div2, #div3, #div4  {
  position:absolute;
}
#div1 {
  top:0
}
#div2 {
  bottom:0;
}

#div3 {
  top:0;
}
#div4 {
  bottom:0;
}




</style>