<script>
  import { Ripple } from "svelte-mui";
  import { fly } from "svelte/transition";
  import Signal  from "../signal";
  import { localStore} from './../localStore'  
 
  
  let computadorasConectadas = window.estadisticas.computadorasConectadas;
  let rozCreados = window.estadisticas.hilosCreados;
  let comentariosCreados = window.estadisticas.comentariosCreados;  
  let registroAbierto = window.estadisticas.registroAbierto;

  let estadisticasStore = localStore("Estadisticas", {visible: false});
  

  function formatear(numero) {
        if (numero >= 1000000)
            return `${(Math.trunc((numero * 1000) / 1000000) / 1000).toFixed(
                3
            )}M`;
        if (numero >= 1000)
            return `${(Math.trunc((numero * 100) / 1000) / 100).toFixed(2)}K`;
        return numero;
    }

  Signal.coneccion.on('estadisticasActualizadas', estadisticas => {
      computadorasConectadas = estadisticas.computadorasConectadas;
      rozCreados = estadisticas.hilosCreados;
      comentariosCreados = estadisticas.comentariosCreados;    
      registroAbierto = estadisticas.registroAbierto;
  })
</script>

{#if $estadisticasStore.visible}
  <div
      class="container"
      transition:fly={{ opacity: 1, y: -48, duration:300 }}
      style="position:absolute; width:100vw;z-index: 1;height: 100%; pointer-events: none !important;">
      <div class="estadisticas " on:click={() => ($estadisticasStore.visible = false)} style="background: var(--color2); border-style: groove; border-color: var(--color5);">
        <span ></span>   
        <span
        class="fe fe-smartphone"
              style="right: 85%;"
              title="Computadoras conectadas"> {formatear(computadorasConectadas)}</span>
          <span
              class="fe fe-edit"
              style="right: 60%;"
              title="Topics creados">{formatear(rozCreados)}</span>
          <span
              class="fe fe-send"
              style="right: 35%;"
              title="Comentarios creados"><span ></span>{formatear(comentariosCreados)}</span>  
              {#if registroAbierto}
                    <span
                    class="fe fe-arrow-right-circle"
                    style="right: 10%;"
                    title="Registros abiertos"><span ></span>Abierto</span>
              {:else}   
                    <span
                    class="fe fe-alert-circle"
                    style="right: 10%;"
                    title="Registros cerrados"><span ></span>Cerrado</span>   
              {/if}                                 
          
      </div>
  </div>
{/if}

<div style="margin-left: 88px;margin-top: 48px;" on:click={() => ($estadisticasStore.visible = true)} class="desplegar-estadisticas">
  <span class="fe fe-bar-chart-2" />
  <Ripple />
</div>

<style>
  .estadisticas {     
      background-size: 100%;
      background-repeat: no-repeat;
      width: 559px;
      height: 37px;
      margin: 0 auto;
      position: relative;
      top: -2px;
      box-shadow: 0 0 6px 1px #00000085;
      pointer-events: visible;
  }
  .estadisticas span {
      position: absolute;
      top: 9px;
      background-color: #f3ec78;
      background-image: linear-gradient(180deg, #ffffff 45%, #040404);
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      font-weight: 600;
      cursor: initial;
  }
  .desplegar-estadisticas {
      margin: 0 auto;
      background: var(--color5);
      font-size: 11px;
      align-self: baseline;
      border-radius: 0 0 4px 4px;
      color: var(--color2);
      padding: 1px 2px;
  }

  @media(max-width:600px) {
      .estadisticas span { top:4px}
      .estadisticas {
          font-size: 10px;
          height: 24px;
          width: 100vw;
      }
  }
</style>