<script>
    import {Textfield, Button, Ripple, Checkbox} from "svelte-mui"
    import RChanClient from "../../RChanClient";
    import Captcha from "../Captcha.svelte";
    import ErrorValidacion from "../ErrorValidacion.svelte";
    import config from "../../config"

    let terminos = false
    let captcha = ""
    let error = null
    let codigo =  ""
    
    if(window.model && window.model.codigoDeInvitacion) {
        codigo = window.model.codigoDeInvitacion
    }

    async function accion(e) {
        console.log(captcha);
        try {
            await RChanClient.inicio(captcha, codigo)
        } catch (e) {
            console.log(e);
            error = e.response.data
            return
        }       
    }


</script>
<div class="fondo"></div>
<main>   

    <div>
        {#if config.general.registroAbierto || codigo}
             <h2>Disclaimer</h2>
            <h5>Para acceder al sitio web de ufftopia.net usted entiende y acepta lo siguiente:</h5>
            <h5>-El contenido de este sitio web es sólo para mayores de edad. Si usted es menor de edad, no ingrese al sitio.</h5>          
            <h5>-Como condición para utilizar este sitio web, usted acepta cumplir tanto las "Reglas" como los "Términos y condiciones" de Ufftopia. <a style="color:var(--primary); font-weight: bold;font-size: 19px;" target="_blank" href="/reglas.html">Ver términos y condiciones</a></h5>            
            <h5>-Al hacer clic en "Aceptar e ingresar", usted acepta no responsabilizar a Ufftopia de ningún daño derivado de su uso del sitio web, y entiende que el contenido publicado no es propiedad ni ha sido generado por ufftopia, sino por los usuarios de ufftopia.net.</h5>           
            <h4>¿Preferís crear una sesión con usuario y contraseña?   <a style="color:var(--primary); text-align:center;"  href="/Registro">Registro</a></h4>
            <br>
            <ErrorValidacion {error}/>
            <br>
            <form on:submit|preventDefault={accion}>
            
            <br>
            <Checkbox bind:checked={terminos}><div style="white-space: normal; text-align: center;">Aceptar términos y condiciones. </div></Checkbox>
            <Captcha visible={config.general.captchaRegistro}  bind:token={captcha}/>
            <br>
            <div>
                <Button style="border-style: groove;" disabled={!terminos}>Aceptar e ingresar</Button>
            </div>
            </form>
        {:else}
            <h2>El inicio de sesiones está temporalmente deshabilitado</h2>
        {/if}
    </div>
</main>

<style>
    main {
        margin:auto;
        height: auto;
        padding: 16px;
        max-width: 1600px;
        margin-top: 80px;
        overflow: hidden;
    }

    section {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        background: var(--color2);
        padding: 16px ;
        border-radius: 4px;
        border-top: solid 2px var(--primary);
    }
    form {
        color: white !important;
        /* background: var(--color2); */
    }

    video {
        position: fixed;
        z-index: -1;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: calc(100vh - 400px);
        width: auto;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0.4;
        /* filter: contrast(1.5) brightness(1.5); */
    }

    .fondo{
        position: fixed;
        top:0;
        left:0;
        width: 100vw;
        height: 100vh;
        z-index: -100;       
        background-size: cover !important;
    }
    :global(.label) {
        color: #ffffffcc !important
    }
</style>