<script>
    import Tiempo from './Tiempo.svelte'
    import RChanClient from '../RChanClient'
    import DialogoReporte from '../components/Dialogos/DialogoReporte.svelte'
    import {Button, ButtonGroup} from 'svelte-mui'
    import { CreacionRango } from '../enums'
    import Spinner from "./Spinner.svelte";

    export let hilo
    export let acciones;
    let mostrarReporte = false
    let cargando = false

    async function seguir() {
        await RChanClient.agregar("seguidos", hilo.id)
        acciones.seguido = !acciones.seguido
    }
    async function ocultar() {
        await RChanClient.agregar("ocultos", hilo.id)
        acciones.hideado = !acciones.hideado
    }
    async function favoritear() {
        await RChanClient.agregar("favoritos", hilo.id)
        acciones.favorito = !acciones.favorito
    }

    let pdf;   
    const initializePdf = () => {  
       pdf = window.html2pdf; 
	}

    async function guardar (){
        try{
             cargando = true
            var opt = {
                        margin:       [0, 0, 0, 0],
                        filename:     'ufftopia-topic-'+ new Date().toJSON().slice(0,10)+'.pdf',
                        image:        { type: 'jpg', quality: 0.98 },
                        html2canvas:  { scale: 2, useCORS: true },
                        jsPDF:        { unit: 'mm', format: 'letter', orientation: 'portrait' }
                        };
            var element = document.getElementsByTagName('body')[0]
    
            // Avoid page-breaks on all elements, and add one before #page2el.
            html2pdf().set({
            pagebreak: { mode: 'avoid-all', before: '#page2el' }
            });

            // Enable all 'modes', with no explicit elements.
            html2pdf().set({
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
            });

           var worker =  html2pdf().set(opt).from(element).save();
           worker.then(newHtml => {                     
                     cargando = false; 
            } )
          
       }catch(error){
        console.error(error);
        cargando = false;
       }
       
    }

</script>
<svelte:head>
  <script src="https://ufftopia.net/html2pdf.bundle.js" on:load={initializePdf}></script>
</svelte:head>

<div class="panel acciones">
    <Button bind:active={acciones.seguido} on:click={seguir} color={acciones.seguido?'var(--primary)':'var(--color-texto2)'}  shaped  ><i class="fe fe-eye"></i>Seg</Button>
    <Button bind:active={acciones.favorito} on:click={favoritear} color={acciones.favorito?'var(--primary)':'var(--color-texto2)'}  shaped  ><i class="fe fe-star"></i>Fav</Button>
    <Button bind:active={acciones.hideado} on:click={ocultar} color={acciones.hideado?'var(--primary)':'var(--color-texto2)'}  shaped  ><i class="fe fe-eye-off"></i>Hide</Button>

    <Button  on:click={() => mostrarReporte = true} shaped color="red" ><i class="fe fe-flag"></i>Rep</Button>
    
   
    <Button  color="var(--color-texto1)"  shaped  disabled ><i class="fe fe-clock"></i><Tiempo date={hilo.creacion} /></Button>
    
    <Spinner {cargando}>
        <Button  on:click={guardar } shaped color="green" disabled={cargando}><i class="fe fe-save"></i>Save</Button>
    </Spinner>
    {#if hilo.rango > CreacionRango.Anon || hilo.nombre}
        <span class="mod">
            {#if hilo.rango > CreacionRango.Anon}
                {CreacionRango.aString(hilo.rango).toUpperCase()}
            {/if}

            {#if hilo.nombre}
                {hilo.nombre}
            {/if}
        </span>
    {/if}
</div>

<DialogoReporte bind:visible={mostrarReporte} tipo="hilo" hiloId={hilo.id} />

<style>
    .acciones {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .mod {
        color: white;
        text-transform: uppercase;
        font-family: terminator;
        font-weight: bold;
        background: var;
        margin-left: auto;
        padding: 6px;
        border-radius: 4px;
        border-top: 4px var(--color5) solid;
    }
</style>