<script>
    import Comentario from '../Comentarios/Comentario.svelte'
    import { fly } from 'svelte/transition';
    import Denuncia from '../Denuncia.svelte'
    import HiloPreview from '../Hilos/HiloPreview.svelte'
    import BarraModeracion from '../Moderacion/BarraModeracion.svelte';
    import ComentarioMod from '../Moderacion/ComentarioMod.svelte';
    import HiloPreviewMod from '../Moderacion/HiloPreviewMod.svelte';
    import Sigal from '../../signal'
    import {Icon, Button, Ripple} from "svelte-mui"

    let hilos = window.model.hilos
    let comentarios = window.model.comentarios
    let denuncias = window.model.denuncias
    let comentariosMedia = window.model.medias

    comentarios = comentarios.map (c => {
        c.respuestas = []
        return c
    })

    Sigal.subscribirAModeracion()
    Sigal.coneccion.on("NuevoComentarioMod", comentario => {
        comentario.respuestas = []
        comentarios.unshift(comentario)
        comentarios.pop()
        comentarios = comentarios
    })
    Sigal.coneccion.on("HiloCreadoMod", hilo => {
        hilos.pop()
        hilos = [hilo, ...hilos]
    })

    Sigal.coneccion.on("categoriaCambiada", (data) => {
        let hilo = hilos.filter((h) => h.id == data.hiloId);
        if (hilo.length != 0) {
            hilo[0].categoriaId = data.categoriaId;
            hilos = hilos;
        }
    });
    
    function getTitle(id){      
        let hilo = hilos.filter(h => h.id == id)
        if(hilo.length != 0) {           
            return hilo[0].titulo;
        }
        return '';
    }

    function soloComentarios(){
        document.getElementById("denunciaId").style.display = "none";
        document.getElementById("hilosId").style.display = "none";
        document.getElementById("idComentarios").style.display = "block";
    }

    function soloHilos(){
        document.getElementById("denunciaId").style.display = "none";
        document.getElementById("hilosId").style.display = "block";
        document.getElementById("idComentarios").style.display = "none";
    }

    function soloDenuncias(){
        document.getElementById("denunciaId").style.display = "block";
        document.getElementById("hilosId").style.display = "none";
        document.getElementById("idComentarios").style.display = "none";
    }

    function mostrarTodos(){
        document.getElementById("denunciaId").style.display = "block";
        document.getElementById("hilosId").style.display = "block";
        document.getElementById("idComentarios").style.display = "block";
    }
</script>
<main>
    <BarraModeracion/>
    <div class="ultimos-medias">
        <ul>
            {#each comentariosMedia as c}
                <li>
                    <a href="/Hilo/{c.hiloId}#{c.id}">
                        <img src="{c.media.vistaPreviaCuadrado}" alt="">
                    </a>
                </li>
            {/each}
        </ul>
    </div>
    <Button style="border-style: dotted;" on:click={mostrarTodos}>Mostrar Todos</Button>
    <div class="seccion2">
        <ul id="denunciaId" style="width:150%; background:#711c08; font-size: 0.7em;    ">
            <h3 style="height:40px">Ultimas denuncias</h3>            
            <Button style="border-style: dotted;" on:click={soloDenuncias}>Solo Denuncias</Button>
            {#each denuncias as d}
                <Denuncia denuncia={d}/>
            {/each}
        </ul>
        <ul id="hilosId" style="width:133%">
            <h3 style="height:40px">Ultimos hilos</h3>        
            <Button style="border-style: dotted;" on:click={soloHilos}>Solo Hilos</Button>
            {#each hilos as h (h.id)}
                <HiloPreviewMod hilo={h}/>
            {/each}
        </ul>
        <ul id="idComentarios" style="width: 157%;">
            <h3 style="height:40px">Ultimos comentarios</h3>
            <Button style="border-style: dotted;" on:click={soloComentarios}>Solo Comentarios</Button>
            {#each comentarios as c}
                <li transition:fly|local={{y: -50, duration:250}}>
                    <ComentarioMod comentario={c} tituloHilo ={getTitle(c.hiloId)}/>
                </li>
            {/each}
        </ul>
    </div>
</main>

<style >
    main {
        display: flex;
        gap: 10px;
        margin: auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .seccion2 {
        width: 100%;
        max-width: 1800px;
        display: flex;
        gap: 10px;
        margin:auto;
        justify-content: center;
    }
    .seccion2 ul {
        max-width: 500px;
        background: var(--color2);
        padding: 10px
    }

    .ultimos-medias ul {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
    }
    .ultimos-medias img {
        width: 64px;
        height: 64px;
        border-radius: 4px;
    }

</style>