import App from './App.svelte';
import Navbar from './components/Navbar.svelte';
import HiloList from './components/Hilos/HiloList.svelte';
import Administracion from './components/Administracion/Administracion.svelte';
import Moderacion from './components/Paginas/Moderacion.svelte';
import Login from './components/Paginas/Login.svelte';
import Registro from './components/Paginas/Registro.svelte';
import Inicio from './components/Paginas/Inicio.svelte';
import Token from './components/Paginas/Token.svelte';
import HistorialUsuario from './components/Paginas/HistorialUsuario.svelte';
import Domado from './components/Paginas/Domado.svelte';
import ListaDeUsuarios from './components/Paginas/ListaDeUsuarios.svelte';
import Busqueda from './components/Paginas/Busqueda.svelte';
import EliminadosYDesactivados from './components/Paginas/EliminadosYDesactivados.svelte';
import FiltroPalabras from './components/Paginas/FiltroPalabras.svelte';
import MediaHome from './components/Paginas/MediaHome.svelte';
import Historial from './components/Paginas/Historial.svelte';

import skinStore from './components/Personalizacion/skinsStore'

skinStore.applicarEstilo()

function deobfuscateJSON(obfuscatedJSON) {
    var charArray = obfuscatedJSON.split('');
    for (var i = 0; i < charArray.length; i++) {
        charArray[i] = String.fromCharCode(charArray[i].charCodeAt(0) - 1);
    }
    return charArray.join('');
}

// Obtener el JSON desofuscado
let result = ""
if(window.hiloList != undefined) {
	var originalJSON = deobfuscateJSON(window.hiloList);
	result = JSON.parse(originalJSON);
}

console.log('1');
window.hiloList = null;
console.log('2');

let componentes = [
	["#svelte", App, {}],
	["#svelte-navbar", Navbar, {}],
	["#svelte-index", HiloList, {hiloList: result}],
	["#svelte-administracion", Administracion, {}],
	["#svelte-moderacion", Moderacion, {}],
	["#svelte-token", Token, {}],
	["#svelte-login", Login, {}],
	["#svelte-historialDeUsuario", HistorialUsuario, {}],
	["#svelte-registro", Registro],
	["#svelte-inicio", Inicio],
	["#svelte-domado", Domado, {}],
	["#svelte-listaDeUsuarios", ListaDeUsuarios, {}],
	["#svelte-eliminadosYDesactivados", EliminadosYDesactivados, {}],
	["#svelte-filtroPalabras", FiltroPalabras, {}],
	["#svelte-media-home", MediaHome, {}],
	["#svelte-busqueda", Busqueda, {}],
	["#svelte-historial", Historial, {}],
]

for (const c of componentes) {
	if(document.querySelector(c[0]))
	{	
		new c[1]({
			target: document.querySelector(c[0]),
			props: c[2]
		});
	}
	
}